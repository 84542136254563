<template>
  <div class="mt-6 flex">
    <template v-for="(link, key) in links" :key="key">
      <div
        v-if="link.url === null"
        class="mr-1 rounded border px-4 py-3 text-sm text-gray-400"
        :class="{ 'ml-auto': link.label === $t('Next') }"
      >
        {{ link.label }}
      </div>
      <inertia-link
        v-else
        class="mr-1 rounded border px-4 py-3 text-sm hover:bg-blue-500 hover:text-white"
        :class="{ 'bg-gray-400 text-white': link.active, 'ml-auto': link.label === $t('Next') }"
        :href="link.url"
        replace
        :preserve-scroll="preserveScroll"
        :preserve-state="preserveState"
      >
        {{ link.label }}
      </inertia-link>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    links: Array,
    highlights: Array,
    preserveScroll: {
      type: Boolean,
      default: false,
    },
    preserveState: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
