<template>
  <div>
    <front-form-section
      v-if="item.type === 'FormSection'"
      :class="{ 'not-draggable': !enabled }"
      :item="item"
      :item-count="itemCount"
      @remove:section="onRemoveSection"
      @add:questionToSection="onAddQuestionToSection"
    >
      <draggable
        v-if="item.items?.length"
        :model-value="item.items"
        tag="div"
        group="items"
        handle=".handle"
        :disabled="!enabled"
        item-key="title"
        ghost-class="ghost"
        :move="onItemMove"
        @start="dragging = true"
        @end="dragging = false"
      >
        <template #item="subElement">
          <div :data-parent-id="subElement.element.parent_id" :data-item-id="subElement.element.id">
            <front-form-item
              :class="{ 'not-draggable': !enabled }"
              :item="subElement.element"
              :item-count="
                subElement.element.type !== 'FormSection' ? item.items?.length : subElement.element.items?.length
              "
            />
          </div>
        </template>
      </draggable>
    </front-form-section>
    <front-form-question
      v-else
      :item="item"
      :item-count="itemCount"
      :class="{ 'not-draggable': !enabled }"
      @remove:question="onRemoveQuestion"
      @change:questionTitle="onChangeQuestionTitle"
    />
  </div>
</template>
<script>
import draggable from 'vuedraggable'

export default {
  components: {
    draggable,
  },
  props: {
    item: Object,
    itemCount: Number,
  },
  emits: ['remove:section', 'remove:question', 'change:questionTitle'],
  data() {
    return {
      enabled: true,
      dragging: false,
    }
  },
  methods: {
    onItemMove: function (e) {
      window.console.log('Future index: ' + e.draggedContext.futureIndex)
    },
    onRemoveSection(item) {
      this.$emit('remove:section', item)
    },
    onRemoveQuestion(item) {
      this.$emit('remove:question', item)
    },
    onChangeQuestionTitle(item, newTitle) {
      this.$emit('change:questionTitle', { item, newTitle })
    },
    onAddQuestionToSection({ section }) {
      this.$emit('add:questionToSection', section)
    },
  },
}
</script>
