<template>
  <a
    target="_blank"
    :href="$page.props.app.tenant.shop_url"
    :class="isTenantWorksite ? 'btn-red-gradient' : 'tenant-btn-1'"
  >
    <slot />
  </a>
</template>

<script>
import { isTenantWorksite } from '@/Utils/Helpers'

export default {
  computed: {
    isTenantWorksite,
  },
}
</script>
