<template>
  <dropdown>
    <slot />
    <span v-if="!hasDefaultSlot">
      <icon v-if="certificate.formats" name="download" class="h-8 w-8 text-sm text-ts-blue-500" />
      <span v-else class="text-sm text-gray-600 opacity-50 hover:cursor-not-allowed">
        <icon name="download" class="h-8 w-8 text-sm text-ts-blue-500" />
      </span>
    </span>
    <template v-if="certificate.formats" #dropdown>
      <div class="relative">
        <div class="absolute right-0 z-20 mt-2 w-48 rounded-md bg-white shadow-xl">
          <a
            v-for="(format, i) in certificate.formats"
            :key="format.id"
            class="block p-4 capitalize text-ts-blue-500 no-underline hover:text-ts-blue-600 hover:underline"
            :class="{ 'border-t border-gray-200': i > 0 }"
            :href="route(downloadRouteName, [certificate, format])"
          >
            {{ $t(format.style) }}
          </a>
          <a
            v-if="certificate.formats.length > 1"
            class="block border-t border-gray-200 p-4 capitalize text-ts-blue-500 no-underline hover:text-ts-blue-600 hover:underline"
            :href="route(downloadAllRouteName, certificate)"
          >
            {{ $t('Download All as Zip') }}
          </a>
        </div>
      </div>
    </template>
  </dropdown>
</template>
<script>
export default {
  props: {
    certificate: {
      type: Object,
      required: true,
    },
    routeName: {
      type: String,
      required: true,
    },
  },
  computed: {
    hasDefaultSlot() {
      return !!this.$slots.default
    },
    downloadRouteName() {
      switch (this.routeName) {
        case 'manager':
          return 'front.manager.certificates.download'
        case 'admin':
          return 'certificates.download'
        default:
          return 'front.certificates.download'
      }
    },
    downloadAllRouteName() {
      switch (this.routeName) {
        case 'manager':
          return 'front.manager.certificates.download.all'
        case 'admin':
          return 'certificates.download.all'
        default:
          return 'front.certificates.download.all'
      }
    },
  },
}
</script>
