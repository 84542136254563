<template>
  <div>
    <div class="my-6">
      <span v-if="item.options.is_required" class="text-red-700">*</span>
      <span>{{ item.title }}</span>
    </div>
    <front-textarea-input
      v-if="item.options.is_multiline"
      v-model="value"
      :required="item.options.is_required"
      :error="error"
    />
    <front-text-input v-else v-model="value" :required="item.options.is_required" :error="error" />
  </div>
</template>
<script>
import FrontTextInput from '@/Shared/FrontTextInput.vue'
import FrontTextareaInput from '@/Shared/FrontTextareaInput.vue'

export default {
  components: {
    FrontTextInput,
    FrontTextareaInput,
  },
  props: {
    modelValue: String,
    item: Object,
    error: [Object, String],
  },
  emits: ['update:modelValue'],
  data() {
    return {
      value: this.modelValue ?? null,
    }
  },
  watch: {
    value() {
      this.$emit('update:modelValue', this.value)
    },
  },
}
</script>
