export default {
  data() {
    return {
      zE_token: null,
      zE_loggedIn: null,
    }
  },
  mounted() {
    this.zE_token = this.$page.props.auth?.user?.zendesk_token
    this.zE_loggedIn = localStorage.zE_loggedIn ?? null

    window.addEventListener('beforeunload', this.onWindowBeforeUnload)
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.onWindowBeforeUnload)
  },
  methods: {
    onWindowBeforeUnload() {
      localStorage.removeItem('zE_loggedIn')
    },
    zE_auth() {
      if (this.zE_token) {
        if (!this.zE_loggedIn) {
          this.zE_loginUser(this.zE_token)
        }
      } else {
        if (this.zE_loggedIn) {
          this.zE_logoutUser()
        }
      }
    },
    zE_loginUser(token) {
      if (!window.zE) return
      window.zE('messenger', 'loginUser', function (callback) {
        callback(token)
      })
      localStorage.setItem('zE_loggedIn', true)
    },
    zE_logoutUser() {
      if (!window.zE) return
      window.zE('messenger', 'logoutUser')
      localStorage.removeItem('zE_loggedIn')
    },
    zE_closeMessenger() {
      window.zE('messenger', 'close')
    },
    zE_openMessenger() {
      window.zE('messenger', 'open')
    },
    zE_enableCookies() {
      window.zE('messenger:set', 'cookies', true)
    },
    zE_disableCookies() {
      window.zE('messenger:set', 'cookies', false)
      localStorage.removeItem('zE_loggedIn')
    },
    zE_hideWidget() {
      if (window.zE) {
        this.zE_disableCookies()
      }
    },
    zE_showWidget() {
      if (window.zE) {
        this.zE_enableCookies()
        this.zE_auth()
      }
    },
  },
}
