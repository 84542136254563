<template>
  <dynamic-search-input
    v-model="user"
    :error="error"
    :url="route('users.search', { organization: organizationId, archived: 'with' })"
    track-by="id"
    :search-by="['name', 'email']"
    :label="label"
  >
    <div v-if="user" class="flex items-center justify-between">
      <div class="truncate">{{ user.name }}</div>
      <div class="whitespace-nowrap text-xs text-gray-600">
        {{ user.email }}
        <br />
        {{ user.organization ? user.organization.name : '' }}
      </div>
    </div>
    <template #option="{ option, selected }">
      <div class="flex items-center justify-between">
        <div>{{ option.name }}</div>
        <div class="text-xs" :class="{ 'text-gray-600': !selected, 'text-white': selected }">
          {{ option.email }}
          <br />
          {{ option.organization ? option.organization.name : '' }}
        </div>
      </div>
    </template>
  </dynamic-search-input>
</template>

<script>
export default {
  props: {
    modelValue: [Object],
    organizationId: {
      type: Number,
      required: false,
      nullable: true,
    },
    label: {
      type: String,
      default: 'User',
    },
    error: String,
  },
  emits: ['update:modelValue'],
  data() {
    return {
      user: this.modelValue,
    }
  },
  watch: {
    user(newUser) {
      this.$emit('update:modelValue', newUser)
    },
  },
}
</script>
