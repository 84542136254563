<template>
  <div>
    <div class="my-6">
      <span v-if="item.options.is_required" class="text-red-700">*</span>
      <span>{{ item.title }}</span>
    </div>
    <front-text-input v-model="value" :required="item.options.is_required" :error="error" />
  </div>
</template>
<script>
import FrontTextInput from '@/Shared/FrontTextInput.vue'

export default {
  components: {
    FrontTextInput,
  },
  props: {
    modelValue: String,
    item: Object,
    error: [Object, String],
  },
  emits: ['update:modelValue'],
  data() {
    return {
      value: this.modelValue ?? null,
    }
  },
  watch: {
    value() {
      this.$emit('update:modelValue', this.value)
    },
  },
}
</script>
