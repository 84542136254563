<template>
  <div>
    <div class="relative">
      <input
        :id="id"
        type="file"
        v-bind="$attrs"
        class="absolute h-full w-full cursor-pointer opacity-0"
        :accept="accept"
        @change="changed"
      />

      <div class="flex" :class="innerClasses">
        <div v-if="!file && !imageUrl" class="w-full bg-gray-400 py-16">
          <icon name="drag-and-drop" class="mx-auto mb-4 h-8 w-8 flex-shrink-0 fill-white" />
          <div class="mx-auto text-center text-gray-900">
            <span class="font-bold">{{ $t('Choose a file') }}</span>
            {{ $t('or drag it here.') }}
          </div>
        </div>
        <img v-else-if="imageUrl" :src="imageUrl" :alt="$t('Image preview...')" />
        <div v-else class="w-full bg-gray-400 py-20">
          <div class="mx-auto text-center text-white">
            {{ file.name }}
          </div>
        </div>
      </div>
    </div>
    <slot name="remove" />
    <div class="form-error">
      <slot name="error">
        <div class="mb-2">{{ error }}</div>
      </slot>
    </div>
    <slot name="caption" />
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    id: {
      type: String,
    },
    error: String,
    accept: {
      type: String,
      required: false,
    },
    url: {
      type: String,
      required: false,
    },
    innerClasses: {
      type: String,
      default: 'justify-center',
      required: false,
    },
  },
  emits: ['input'],
  data() {
    return {
      file: null,
      imageUrl: this.url,
    }
  },
  methods: {
    changed(event) {
      if (!event.target.files.length) return

      this.imageUrl = null

      this.$emit('input', (this.file = event.target.files[0]))

      if (this.file.type.startsWith('image')) {
        let reader = new FileReader()

        reader.onload = (e) => (this.imageUrl = e.target.result)

        reader.readAsDataURL(this.file)
      }
    },
  },
}
</script>
