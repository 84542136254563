<template>
  <div
    class="no-print flex justify-around overflow-hidden p-2 md:rounded-full"
    :class="isTenantWorksite() ? 'bg-qualify-red-400' : 'bg-blue-600'"
  >
    <inertia-link :href="route('front.courses')">
      <div
        class="group flex cursor-pointer select-none items-center rounded-full p-2"
        :class="!(!isUrl('account/manager*') && !isUrl('account/admin*')) ? btnCssClass : btnActiveCssClass"
      >
        <div
          class="w-24 whitespace-nowrap text-center text-sm font-semibold text-white sm:w-28 sm:text-base md:w-32 lg:text-sm"
        >
          <span>{{ $t('You') }}</span>
        </div>
      </div>
    </inertia-link>
    <inertia-link v-if="canViewManagementSection" :href="route('front.manager.users')">
      <div
        class="group flex cursor-pointer select-none items-center rounded-full p-2"
        :class="!isUrl('account/manager*') ? btnCssClass : btnActiveCssClass"
      >
        <div
          class="w-24 whitespace-nowrap text-center text-sm font-semibold text-white sm:w-28 sm:text-base md:w-32 lg:text-sm"
        >
          <span>{{ $t('Management') }}</span>
        </div>
      </div>
    </inertia-link>
    <button v-else-if="!$page.props.auth.user.organization" @click="openOrganizationModal">
      <div class="group flex cursor-pointer select-none items-center rounded-full p-2">
        <div
          class="w-24 whitespace-nowrap text-center text-sm font-semibold text-white sm:w-28 sm:text-base md:w-32 lg:text-sm"
        >
          <span>{{ $t('Management') }}</span>
        </div>
      </div>
    </button>
    <inertia-link v-if="canViewAdminSection" :href="route('front.admin.settings')">
      <div
        class="group flex cursor-pointer select-none items-center rounded-full p-2"
        :class="!isUrl('account/admin*') ? btnCssClass : btnActiveCssClass"
      >
        <div
          class="w-24 whitespace-nowrap text-center text-sm font-semibold text-white sm:w-28 sm:text-base md:w-32 lg:text-sm"
        >
          <span>{{ $t('Admin') }}</span>
        </div>
      </div>
    </inertia-link>
    <button v-else-if="!$page.props.auth.user.organization" @click="openOrganizationModal">
      <div class="group flex cursor-pointer select-none items-center rounded-full p-2">
        <div
          class="w-24 whitespace-nowrap text-center text-sm font-semibold text-white sm:w-28 sm:text-base md:w-32 lg:text-sm"
        >
          <span>{{ $t('Admin') }}</span>
        </div>
      </div>
    </button>
  </div>
</template>

<script>
import { isUrl } from '@/Utils/Helpers'
import { isTenantWorksite } from '@/Utils/Helpers'

export default {
  emits: ['open:organization-modal'],
  data() {
    return {}
  },
  computed: {
    canViewManagementSection() {
      return (
        ['admin', 'manager', 'groupManager', 'locationManager'].includes(
          this.$page.props.auth.user.organization_role
        ) && this.$page.props.auth.user.organization
      )
    },
    canViewAdminSection() {
      return this.$page.props.auth.user.organization_role === 'admin' && this.$page.props.auth.user.organization
    },
    btnCssClass() {
      return this.isTenantWorksite() ? 'bg-qualify-red-400' : 'bg-blue-600'
    },
    btnActiveCssClass() {
      return this.isTenantWorksite() ? 'bg-qualify-red-300' : 'bg-blue-500'
    },
  },
  methods: {
    isUrl,
    isTenantWorksite,
    openOrganizationModal() {
      this.$emit('open:organization-modal')
    },
  },
}
</script>
