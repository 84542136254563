<template>
  <div v-show="show" class="absolute z-50 flex">
    <div class="fixed inset-0 z-40 h-screen w-full bg-transparent" @click.self.stop="close" />
    <div class="relative z-50 w-auto">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      required: true,
      type: Boolean,
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.4s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
