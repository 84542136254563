<template>
  <form @submit.prevent="(e) => $emit('submit', e)">
    <div class="flex items-center">
      <div class="flex w-full rounded bg-white shadow">
        <dropdown
          v-if="filterShow"
          class="flex items-center rounded-l border-r px-4 hover:bg-gray-100 focus:z-10 focus:border-white focus:ring md:px-6"
          placement="bottom-start"
        >
          <span class="hidden text-gray-800 md:block">{{ $t('Filter') }}</span>
          <svg class="h-2 w-2 fill-gray-700 md:ml-2" viewBox="0 0 961.243 599.998">
            <path
              d="M239.998 239.999L0 0h961.243L721.246 240c-131.999 132-240.28 240-240.624 239.999-.345-.001-108.625-108.001-240.624-240z"
            />
          </svg>
          <template #dropdown>
            <div class="mt-2 w-screen bg-white px-4 py-6 shadow-lg" :style="{ maxWidth: `${maxWidth}px` }">
              <slot />
            </div>
          </template>
        </dropdown>
        <input
          class="relative w-full px-6 py-3 leading-normal focus:z-10 focus:ring"
          :class="{ 'rounded-l': !filterShow, 'rounded-r': !hasSearchByField, 'border-r': hasSearchByField }"
          autocomplete="off"
          type="text"
          name="search"
          :placeholder="placeholder"
          :value="modelValue"
          @input="$emit('update:modelValue', $event.target.value)"
        />
        <slot name="searchByField" />
      </div>
      <button v-if="showSubmitButton" class="btn-blue mx-2 px-4" type="submit">
        <icon name="search" class="block h-5 w-5 text-white" />
      </button>
      <button class="ml-3 text-sm text-gray-600 hover:text-gray-700" type="reset" @click.prevent="$emit('reset')">
        {{ $t('Reset') }}
      </button>
    </div>
  </form>
</template>

<script>
export default {
  props: {
    modelValue: String,
    maxWidth: {
      type: Number,
      default: 300,
    },
    placeholder: {
      type: String,
      default: 'Search…',
    },
    filterShow: {
      type: Boolean,
      default: true,
    },
    showSubmitButton: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'submit', 'reset'],
  computed: {
    hasSearchByField() {
      return !!this.$slots.searchByField
    },
  },
}
</script>
