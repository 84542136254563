<template>
  <menu-item
    v-if="$page.props.auth.can.access_admin_backoffice"
    class="hidden md:block"
    :name="$t('Admin')"
    :route-link="route('dashboard')"
  >
    <icon name="setting" class="mr-3 h-5 w-5" />
  </menu-item>
</template>
<script>
import { isTenantWorksite } from '@/Utils/Helpers'
export default {
  computed: {
    isTenantWorksite,
  },
}
</script>
