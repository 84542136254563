<template>
  <span>{{ formattedPrice }}</span>
</template>
<script>
export default {
  props: {
    value: Number,
  },
  computed: {
    formattedPrice() {
      return this.value.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })
    },
  },
}
</script>
