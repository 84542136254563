<template>
  <div class="flex h-32 w-full rounded-lg border bg-white px-8 py-4 sm:h-64" style="max-height: 65vh">
    <div class="mr-4 grid gap-2">
      <div class="my-3">
        <div class="mb-1 font-semibold">{{ $t('Name') }}</div>
        <div>{{ certificate.pdf_fields.student_name }}</div>
      </div>
      <div class="my-3">
        <div class="mb-1 font-semibold">{{ $t('Number') }}</div>
        <div>{{ certificate.pdf_fields.certificate_number }}</div>
      </div>
      <div class="my-3">
        <div class="mb-1 font-semibold">{{ $t('Employer') }}</div>
        <div>
          <template v-if="certificate.pdf_fields.employer_name">
            <span>{{ certificate.pdf_fields.employer_name }}</span>
            <br />
          </template>
          <span v-if="certificate.pdf_fields.employer_address">
            {{ certificate.pdf_fields.employer_address }}
          </span>
        </div>
      </div>
    </div>
    <div>
      <div class="py-4">
        <div class="mb-1 font-semibold">{{ $t('Issued On') }}</div>
        <div>{{ certificate.pdf_fields.created_at }}</div>
      </div>
      <div class="my-4">
        <div class="mb-1 font-semibold">{{ $t('Expires On') }}</div>
        <div class="">{{ certificate.pdf_fields.expires_at }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    certificate: Object,
  },
}
</script>
