<template>
  <button class="btn-link" @click="(e) => $emit('click', e)">
    <slot />
  </button>
</template>

<script>
export default {
  emits: ['click'],
}
</script>
