<template>
  <dynamic-search-input
    v-model="action"
    :error="error"
    :url="route('front.actions.search')"
    track-by="id"
    :search-by="['title', 'description']"
    :label="label"
  >
    <div v-if="action" class="flex items-center justify-between">
      <div class="truncate">{{ action.title }}</div>
    </div>
    <template #option="{ option }">
      <div class="flex items-center justify-between">
        <div>{{ option.title }}</div>
      </div>
    </template>
  </dynamic-search-input>
</template>

<script>
export default {
  props: {
    modelValue: [Object],
    organizationId: {
      type: Number,
      required: false,
      nullable: true,
    },
    label: {
      type: String,
      default: 'Action',
    },
    error: String,
  },
  emits: ['update:modelValue'],
  data() {
    return {
      action: this.modelValue,
    }
  },
  watch: {
    action(newAction) {
      this.$emit('update:modelValue', newAction)
    },
  },
}
</script>
