<template>
  <div>
    <label>
      <span v-if="label" class="form-label">{{ label }}</span>
      <media-library-collection
        :ref="refName"
        :name="refName"
        :initial-value="initialValue"
        :min-items="minItems"
        :max-items="maxItems"
        :validation-rules="validationRules"
        @change="(media) => (files = media)"
      />
    </label>
    <div v-if="error" class="form-error">
      <span v-if="Array.isArray(error)">{{ error[0] }}</span>
      <span v-else>{{ error }}</span>
    </div>
  </div>
</template>

<script>
import '@spatie/laravel-medialibrary-pro/media-library-pro-styles/src/styles.css'
import { MediaLibraryCollection } from '@spatie/laravel-medialibrary-pro/media-library-pro-vue3-collection'

export default {
  components: {
    MediaLibraryCollection,
  },
  props: {
    id: String,
    refName: String,
    label: String,
    initialValue: Array,
    validationRules: Object,
    minItems: Number,
    maxItems: Number,
    error: [String, Array],
  },
  emits: ['update:modelValue'],
  data() {
    return {
      files: this.initialValue,
    }
  },
  watch: {
    files() {
      this.$emit('update:modelValue', this.files)
    },
  },
}
</script>
