<template>
  <front-modal :show="show" class="rounded text-base" width="auto" @close="close">
    <div class="max-w-sm overflow-hidden rounded bg-white p-6 shadow md:p-10">
      <div class="mb-4 text-center">
        <h1 class="mt-4 text-3xl font-bold">{{ $t('Send Certificates') }}</h1>
        <h3 class="mt-4 text-xl font-semibold leading-snug">
          {{ $t('Enter your email below to receive your certificate.') }}
        </h3>
      </div>
      <form @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-wrap py-8 md:px-12">
          <div class="w-full pb-8 pr-6">
            <front-text-input v-model="name" :label="$t('Name')" type="text" required />
          </div>
          <div class="w-full pb-8 pr-6">
            <front-text-input v-model="email" :label="$t('Email')" type="email" required />
          </div>
        </div>
        <div class="flex items-center justify-center bg-white px-8 pb-8 pt-3">
          <front-button-link type="button" @click="close">{{ $t('Close') }}</front-button-link>
          <front-solid-button type="submit" class="ml-4">{{ $t('Send') }}</front-solid-button>
        </div>
      </form>
    </div>
  </front-modal>
</template>

<script>
import { query } from '@/Utils/Helpers'

export default {
  props: {
    show: Boolean,
    certificate: Object,
  },
  data() {
    return {
      name: null,
      email: null,
    }
  },
  methods: {
    query,
    close() {
      this.$emit('close')
    },
    submit() {
      this.$inertia.visit(
        this.route('front.certificates.email', {
          certificate: this.certificate.id,
          name: this.name,
          email: this.email,
        })
      )
    },
  },
}
</script>
