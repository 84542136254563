<template>
  <div v-if="lastModified" class="overflow-hidden rounded bg-white shadow">
    <div class="bg-gray-400 px-4 py-3 text-sm font-bold text-gray-700">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="inline-block h-5 w-5 stroke-current"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <polyline points="12 8 12 12 14 14" />
        <path d="M3.05 11a9 9 0 1 1 .5 4m-.5 5v-5h5" />
      </svg>
      <span class="ml-1">{{ $t('Last Version') }}</span>
    </div>
    <div class="flex flex-wrap p-4">
      <div class="grid w-full grid-cols-1 gap-2 text-sm">
        <div class="flex space-x-1">
          <span><app-datetime :utc-date="lastModified" /></span>
          <span>
            <inertia-link class="text-blue-400 underline" :href="link">{{ $t('see more') }}</inertia-link>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    lastModified: String,
    link: String,
  },
}
</script>
