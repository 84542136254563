<template>
  <div>
    <div class="my-6">
      <span v-if="item.options.is_required" class="text-red-700">*</span>
      <span>{{ item.title }}</span>
    </div>
    <p v-if="item.options.format_type === 'Temperature'" class="my-4 text-sm text-gray-600">
      {{
        $t('Temperature should be between :temp_min - :temp_max:temp_unit', {
          temp_min: item.options.temp_min,
          temp_max: item.options.temp_max,
          temp_unit: tempUnits[item.options.temp_unit],
        })
      }}
    </p>
    <front-text-input v-model="value" type="number" :required="item.options.is_required" :error="error" />
  </div>
</template>
<script>
import { TempUnitEnum } from '@/Enums/QuestionableEnums.js'
import FrontTextInput from '../FrontTextInput.vue'

export default {
  components: {
    FrontTextInput,
  },
  props: {
    modelValue: String,
    item: Object,
    error: [Object, String],
  },
  emits: ['update:modelValue'],
  data() {
    return {
      value: this.modelValue ?? null,
      tempUnits: TempUnitEnum,
    }
  },
  watch: {
    value() {
      this.$emit('update:modelValue', this.value)
    },
  },
}
</script>
