<template>
  <inertia-link
    v-if="routeLink"
    v-bind="$attrs"
    :href="routeLink"
    class="mb-0 relative group -ml-3 flex items-center whitespace-nowrap text-lg focus:text-white hover:text-white"
    :class="{
      'hover:bg-qualify-red-500 focus:bg-qualify-red-500': isTenantWorksite,
      'hover:bg-blue-600 focus:bg-blue-600': !isTenantWorksite,
      'text-qualify-red-500 fill-qualify-red-500': isActive && isTenantWorksite,
      'text-blue-500 fill-blue-500': isActive && !isTenantWorksite,
      'text-ts-gray-text-bold': !isActive,
    }"
    :method="method"
  >
    <div
      v-if="isActive"
      class="absolute ml-0 w-1 h-full"
      :class="isTenantWorksite ? 'bg-qualify-red-500' : 'bg-blue-600'"
    >
      &nbsp;
    </div>
    <div v-else class="absolute ml-0 w-1 h-full">&nbsp;</div>
    <div class="flex items-center ml-10 py-2">
      <slot />
      <span :class="{ 'border-white ': !isUrl(url) }">
        {{ $t(name) }}
      </span>
    </div>
  </inertia-link>
  <div
    v-else
    class="group -ml-3 flex items-center whitespace-nowrap border-l-4 border-white px-8 py-2"
    :class="isUrl(url) ? 'text-ts-gray-text-bold' : 'text-gray-600'"
  >
    <slot />
    <span>
      {{ $t(name) }}
    </span>
  </div>
</template>

<script>
import { isTenantWorksite, isUrl } from '@/Utils/Helpers'

export default {
  inheritAttrs: false,
  props: {
    name: String,
    routeLink: String,
    url: {
      type: [String, Array],
      default: '',
    },
    method: {
      type: String,
      default: 'get',
    },
  },
  computed: {
    isTenantWorksite,
    isActive() {
      return this.isUrl(this.url)
    },
  },
  methods: {
    isUrl,
  },
}
</script>
