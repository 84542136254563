<template>
  <div :class="`${cssClasses}`">
    <slot />
    <span v-if="!hasDefaultSlot">{{ status }}</span>
  </div>
</template>
<script>
export default {
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  data() {
    const cssClasses = 'inline-block px-2 py-1 rounded-md text-center'
    let statusClasses
    switch (this.status) {
      case 'Expired':
        statusClasses = 'bg-ts-red-100 text-ts-red-500'
        break
      case 'Expiring':
        statusClasses = 'bg-ts-orange-200 text-ts-orange-500'
        break
    }
    return {
      cssClasses: `${cssClasses} ${statusClasses}`,
    }
  },
  computed: {
    hasDefaultSlot() {
      return !!this.$slots.default
    },
  },
}
</script>
