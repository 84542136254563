<template>
  <div
    v-if="enrolment.submitted === true || enrolment.skillspass_status === 'finished'"
    :class="`${cssClasses} bg-ts-blue-500 bg-opacity-8 text-ts-blue-500`"
  >
    {{ $t('Submitted') }}
  </div>
  <div
    v-else-if="enrolment.skillspass_status === 'pending'"
    :class="`${cssClasses} bg-ts-orange-900 bg-opacity-8 text-ts-orange-900`"
  >
    {{ $t('Pending') }}
  </div>
  <div v-else class="pt-3">
    <a
      v-if="enrolment.is_classroom_type && enrolment.class_ && enrolment.can.submitClassToMinistryOfLabour"
      :href="route('classes.show', enrolment.class_.id)"
      class="btn-blue"
    >
      Submit Student
    </a>
    <a v-else-if="!enrolment.is_classroom_type" href="#" class="btn-blue" @click="submit">Submit to MOL</a>
    <div v-else :class="`${cssClasses} bg-ts-orange-900 bg-opacity-8 text-ts-orange-900`">
      {{ $t('Not Submitted') }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    enrolment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      cssClasses: 'inline-block px-2 py-3 rounded-md text-sm text-center font-semibold w-24',
    }
  },
  methods: {
    submit() {
      this.$inertia.post(this.route('enrolments.mol.submit', this.enrolment.id), [])
    },
  },
}
</script>
