<template>
  <div>
    <div class="my-6">
      <span v-if="formAnswer.form_content.is_required" class="text-red-700">*</span>
      <span>{{ formAnswer.form_content.title }}</span>
    </div>
    <div class="grid grid-cols-2 gap-4 text-sm">
      <front-date-input
        v-if="formAnswer.form_content.form_questionable.is_date"
        v-model="options.date_value"
        :label="$t('Date')"
        :placeholder="$t('Enter date')"
        :error="error"
      />
      <time-input
        v-if="formAnswer.form_content.form_questionable.is_time"
        v-model="options.time_value"
        :label="$t('Time')"
        :placeholder="$t('Enter time')"
        :error="error"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    modelValue: String,
    formAnswer: Object,
    error: [Object, String],
  },
  emits: ['update:modelValue'],
  data() {
    return {
      value: this.modelValue ?? null,
      options: {
        date_value: this.formAnswer.form_content.form_questionable.is_date
          ? this.modelValue
            ? this.modelValue.split('|')[0]
            : new Date()
          : null,
        time_value: this.formAnswer.form_content.form_questionable.is_time
          ? this.modelValue
            ? this.modelValue.split('|')[1]
            : '12:00am'
          : null,
      },
    }
  },
  watch: {
    options: {
      handler: function () {
        if (
          this.formAnswer.form_content.form_questionable.is_date &&
          this.formAnswer.form_content.form_questionable.is_time
        ) {
          this.value = this.options.date_value + '|' + this.options.time_value
        } else if (!this.formAnswer.form_content.form_questionable.is_date) {
          this.value = this.date_value
        } else {
          this.value = this.time_value
        }
        this.$emit('update:modelValue', this.value)
      },
      deep: true,
    },
  },
}
</script>
