<template>
  <div class="overflow-hidden rounded bg-white shadow">
    <a :href="route('organizations.edit', organization.id)" class="block px-4 py-6 hover:bg-gray-100">
      <div class="font-bold">{{ organization.name }}</div>
      <div class="mt-4 leading-tight text-gray-600">
        <div>{{ organization.address }}</div>
        <div>{{ organization.city }}, {{ organization.region }} {{ organization.postal_code }}</div>
      </div>
    </a>
    <div v-if="organization.owners.length" class="bg-gray-300 p-4 text-sm font-bold text-gray-700">
      {{ $t('Owners') }}
    </div>
    <a
      v-for="(owner, index) in organization.owners"
      :key="owner.id"
      :href="route('users.edit', owner.id)"
      class="block px-4 py-4 hover:bg-gray-100"
      :class="{ 'border-t': index }"
    >
      <div>{{ owner.first_name }} {{ owner.last_name }}</div>
      <div v-if="owner.email" class="mt-2 text-gray-600">{{ owner.email }}</div>
      <div v-if="owner.phone" class="mt-1 text-gray-600">{{ owner.phone }}</div>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    organization: Object,
  },
}
</script>
