<template>
  <div class="relative -mx-3 -mt-2">
    <label>
      <input
        :id="id"
        type="radio"
        v-bind="$attrs"
        :checked="isChecked"
        :value="value"
        :class="isTenantWorksite ? 'worksite-tenant' : 'other-tenant'"
        :name="name"
        @change="$emit('change', value)"
      />
      <span />
    </label>
  </div>
</template>

<script>
import { isTenantWorksite } from '@/Utils/Helpers'

export default {
  inheritAttrs: false,
  props: {
    id: String,
    name: String,
    value: {
      type: [String, Number, Object],
      default: null,
    },
    modelValue: {
      type: [Boolean, Array, Object],
      default: null,
    },
    trueValue: {
      type: [Boolean, Object, String, Number],
      default: true,
    },
    falseValue: {
      type: [Boolean, Object, String, Number],
      default: false,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    isTenantWorksite,
    isChecked() {
      if (this.modelValue instanceof Array) {
        return this.modelValue.includes(this.value)
      }

      return this.modelValue == this.value
    },
  },
}
</script>
<style scoped>
input[type='radio'] {
  visibility: hidden;
}

input[type='radio'] ~ span {
  background-color: #f5f5f5;
  border: 1px solid #ededed;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}

input[type='radio'] ~ span:after {
  border: 2px solid #ededed;
  border-top: none;
  border-right: none;
  content: '';
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

input[type='radio'].worksite-tenant:checked + span {
  background-color: #dd163d;
  border-color: #dd163d;
}
input[type='radio'].other-tenant:checked + span {
  background-color: #2b6cb0;
  border-color: #2b6cb0;
}

input[type='radio']:checked + span:after {
  opacity: 1;
}

input[type='radio']:disabled + span {
  opacity: 0.5;
}
</style>
