<template>
  <div class="bg-gray-300 px-8 py-4 text-sm font-bold text-gray-700">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'FormSectionHeader',
}
</script>
