<template>
  <inertia-link
    v-bind="$attrs"
    :href="url"
    class="whitespace-nowrap border-b-4 px-1 py-4 font-bold leading-5 focus:outline-none"
    :class="cssClass"
  >
    <slot />
    <span :class="{ 'border-white text-ts-gray-text-bold': !isActive }">
      {{ $t(name) }}
    </span>
  </inertia-link>
</template>

<script>
import { isTenantWorksite, urlMatches } from '@/Utils/Helpers'

export default {
  inheritAttrs: false,
  props: {
    name: String,
    url: String,
    matchUrl: {
      type: [String, Array],
      default: '',
    },
    method: {
      type: String,
      default: 'get',
    },
  },
  computed: {
    isTenantWorksite,
    isActive() {
      if (this.urlMatches(this.$page.url, this.matchUrl)) {
        return true
      }
      return false
    },
    cssClass() {
      if (this.isActive) {
        return this.isTenantWorksite
          ? 'border-qualify-red-500 text-gray-800 focus:text-gray-800'
          : 'border-blue-700 text-gray-800 focus:text-gray-800'
      }

      return 'border-transparent text-ts-gray-text-bold hover:text-gray-700 hover:border-gray-200 focus:text-gray-700 focus:border-gray-200'
    },
  },
  methods: {
    urlMatches,
  },
}
</script>
