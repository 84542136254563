<template>
  <span
    class="rounded px-2 py-1 text-sm text-white"
    :class="{
      'animate-pulse bg-gray-400': status === 'pending',
      'bg-yellow-400': status === 'partial',
      'bg-green-400': status === 'finished',
      'bg-red-400': status === 'failed',
    }"
    :title="status"
  >
    {{ $t('MOL') }}
  </span>
</template>

<script>
export default {
  name: 'MinistryOfLabourStatusBadge',
  props: {
    status: String,
  },
}
</script>
