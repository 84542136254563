<template>
  <div
    v-if="status === 'finished' || submitted === true"
    :class="`${cssClasses} bg-ts-green-500 bg-opacity-8 text-ts-green-500`"
  >
    {{ $t('Submitted') }}
  </div>
  <div v-else-if="status === 'pending'" :class="`${cssClasses} bg-ts-blue-500 bg-opacity-8 text-ts-blue-500`">
    {{ $t('Pending') }}
  </div>
  <div v-else-if="status === 'failed'" :class="`${cssClasses} bg-red-500 bg-opacity-8 text-ts-red-500`">
    {{ $t('Failed') }}
  </div>
  <div v-else :class="`${cssClasses} bg-ts-gray-text-bold bg-opacity-8 text-ts-gray-text-bold`">
    {{ $t('Not Submitted') }}
  </div>
</template>
<script>
export default {
  props: {
    submitted: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
    },
  },
  data() {
    return {
      cssClasses: 'inline-block px-2 py-3 rounded-md text-sm text-center font-semibold w-28',
    }
  },
}
</script>
