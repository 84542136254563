<template>
  <div>
    <label>
      <span v-if="label" class="form-label mb-3 text-ts-front-label">{{ label }}</span>
      <quill-editor v-model:content="content" :toolbar="customToolbar" theme="snow" debug="info" content-type="html" />
    </label>
    <div v-if="error" class="form-error">{{ error }}</div>
  </div>
</template>

<script>
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'

export default {
  components: {
    QuillEditor,
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    label: String,
    error: String,
  },
  emits: ['update:modelValue'],
  data() {
    return {
      content: this.modelValue,
      customToolbar: [
        [{ size: ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link', 'image'],
        ['clean'],
      ],
    }
  },
  watch: {
    content() {
      this.$emit('update:modelValue', this.content)
    },
  },
}
</script>
