<template>
  <answer-view :label="formAnswer.form_content.title">
    {{ modelValue }}
  </answer-view>
</template>
<script>
import AnswerView from './AnswerView.vue'

export default {
  components: {
    AnswerView,
  },
  props: {
    modelValue: String,
    formAnswer: Object,
  },
}
</script>
