<template>
  <div class="w-12 h-12 rounded overflow-hidden cursor-pointer">
    <img
      v-if="media.type === 'image'"
      :src="thumbnailUrl"
      alt="Media"
      :title="media.file_name"
      class="block w-full h-full object-cover"
    />
    <div
      v-else
      :title="media.file_name"
      class="block flex w-full h-full items-center justify-center rounded border bg-gray-200 object-cover text-xs uppercase"
    >
      {{ media.type }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    media: Object,
  },
  data() {
    return {
      showImageModal: false,
    }
  },
  computed: {
    thumbnailUrl() {
      if (this.media.type === 'image') {
        return this.media.thumbnail_url ? this.media.thumbnail_url : this.media.url
      }
      return null
    },
  },
}
</script>
