<template>
  <div class="ml-2 text-xs text-gray-600">
    <div v-if="model.created_at" class="mt-4">{{ $t('Created on :created_at', { created_at: model.created_at }) }}</div>
    <div v-if="model.created_at !== model.updated_at" class="mt-1">
      {{ $t('Updated on :updated_at', { updated_at: model.updated_at }) }}
    </div>
    <div v-if="model.deleted_at" class="mt-1">{{ $t('Deleted on :deleted_at', { deleted_at: model.deleted_at }) }}</div>
    <div v-if="model.archived_at" class="mt-1">
      {{ $t('Archived on :archived_at', { archived_at: model.archived_at }) }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    model: Object,
  },
}
</script>
