<template>
  <div>
    <div class="my-6">
      <span v-if="item.options.is_required" class="text-red-700">*</span>
      <span>{{ item.title }}</span>
    </div>
    <div class="grid grid-cols-2 gap-4 text-sm">
      <date-input
        v-if="item.options.is_date"
        v-model="options.date_value"
        :label="$t('Date')"
        :placeholder="$t('Enter date')"
        :error="error"
      />
      <time-input
        v-if="item.options.is_time"
        v-model="options.time_value"
        :label="$t('Time')"
        :placeholder="$t('Enter time')"
        :error="error"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    modelValue: String,
    item: Object,
    error: [Object, String],
  },
  emits: ['update:modelValue'],
  data() {
    return {
      value: this.modelValue ?? null,
      options: {
        date_value: this.item.options.is_date ? (this.modelValue ? this.modelValue.split('|')[0] : new Date()) : null,
        time_value: this.item.options.is_time ? (this.modelValue ? this.modelValue.split('|')[1] : '12:00am') : null,
      },
    }
  },
  watch: {
    options: {
      handler: function () {
        if (this.item.options.is_date && this.item.options.is_time) {
          this.value = this.options.date_value + '|' + this.options.time_value
        } else if (!this.item.options.is_date) {
          this.value = this.date_value
        } else {
          this.value = this.time_value
        }
        this.$emit('update:modelValue', this.value)
      },
      deep: true,
    },
  },
}
</script>
