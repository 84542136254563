<template>
  <tr class="even:bg-gray-50 hover:bg-gray-200 focus:bg-gray-200" :class="{ 'cursor-pointer': clickable }">
    <slot />
  </tr>
</template>
<script>
export default {
  props: {
    clickable: Boolean,
  },
}
</script>
