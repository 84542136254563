<template>
  <card name="Calendar Feed">
    <div class="w-full">
      <input type="text" class="form-input" :value="calendarUrl" disabled />
    </div>
  </card>
</template>

<script>
export default {
  props: { calendarUrl: String },
}
</script>
