<template>
  <time :datetime="utcDate">{{ displayDateTimeString }}</time>
</template>

<script>
export default {
  props: {
    utcDate: {
      type: String,
      required: true,
    },
  },
  computed: {
    displayDateTimeString: function () {
      const locale = navigator.language || navigator.userLanguage

      return new Date(this.utcDate).toLocaleDateString(locale, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      })
    },
  },
}
</script>
