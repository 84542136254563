<template>
  <menu-item :name="name" :route-link="routeLink" :url="url" class="mb-1">
    <icon :v-if="icon" :name="icon" class="mr-3 h-5 w-5" fill="currentColor" />
  </menu-item>
</template>

<script>
import MenuItem from './MenuItem.vue'

export default {
  components: { MenuItem },
  props: {
    name: String,
    routeLink: String,
    icon: String,
    url: {
      type: [String, Array],
      default: '',
    },
  },
}
</script>
