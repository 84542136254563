import Http from '../Utils/Http'

export default {
  data() {
    return {
      timezone: this.$page.props.auth?.user.timezone,
      isImpersonating: this.$page.props.isImpersonating,
    }
  },
  methods: {
    setTimezone() {
      if (!this.timezone && !this.isImpersonating) {
        let tz = Intl.DateTimeFormat().resolvedOptions().timeZone
        if (tz) {
          Http.put(this.route('front.users.timezone'), {
            timezone: tz,
          }).catch((error) => {
            console.log(error)
          })
        }
      }
    },
  },
}
