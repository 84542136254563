<template>
  <inertia-link :class="isTenantWorksite ? 'btn-red-gradient' : 'tenant-btn-1'" class="" :href="href ? href : '#'">
    <slot />
  </inertia-link>
</template>

<script>
import { isTenantWorksite } from '@/Utils/Helpers'

export default {
  props: {
    href: String,
  },
  computed: {
    isTenantWorksite,
  },
}
</script>
