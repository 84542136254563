<template>
  <div class="w-full">
    <label>
      <span v-if="label" class="form-label text-ts-front-label">{{ label }}</span>
      <select
        :id="id"
        ref="input"
        v-model="selected"
        v-bind="$attrs"
        class="form-select mt-1 w-full rounded-lg border border-ts-front-border-light bg-ts-front-field-light p-3 pr-6 text-gray-700 focus:border-ts-front-border-dark focus:text-gray-800 focus:outline-none"
        :class="{ 'border-ts-front-border-error': error }"
      >
        <slot>
          <option v-for="(option, key) in options" :key="key" :value="option">{{ option }}</option>
        </slot>
      </select>
    </label>
    <div v-if="error" class="form-error">{{ error }}</div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    id: {
      type: String,
    },
    modelValue: [String, Number, Boolean],
    options: Array,
    label: String,
    error: String,
  },
  emits: ['update:modelValue'],
  data() {
    return {
      selected: this.modelValue,
    }
  },
  watch: {
    modelValue() {
      this.selected = this.modelValue
    },
    selected(selected) {
      this.$emit('update:modelValue', selected)
    },
  },
  methods: {
    focus() {
      this.$refs.input.focus()
    },
    select() {
      this.$refs.input.select()
    },
  },
}
</script>
