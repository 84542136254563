export function logoName(color) {
  return `${color} ${location.host}`
}

export function isUrl(urls, currentPath = location.pathname) {
  if (!Array.isArray(urls)) {
    urls = [urls]
  }
  return urls?.flat().filter((url) => {
    if (url.slice(-1) === '*') {
      return currentPath.substr(1).startsWith(url.slice(0, -1))
    } else if (url.slice(0, 1) === '*') {
      return currentPath.substr(1).endsWith(url.slice(1))
    } else {
      return currentPath.substr(1) === url.replace(/\/$/, '')
    }
  }).length
}

export function urlMatches(currentUrl, ...urls) {
  return urls?.flat().filter((url) => {
    if (url.slice(-1) === '*') {
      return currentUrl.startsWith(url.slice(0, -1))
    } else if (url.slice(0, 1) === '*') {
      return currentUrl.endsWith(url.slice(1))
    } else {
      return currentUrl === url.replace(/\/$/, '')
    }
  }).length
}

export function isNotUrl(prefix, ...urls) {
  return !isUrl(prefix, ...urls)
}

export function query(data) {
  return '?' + new URLSearchParams(data).toString()
}

export function isTenantWorksite() {
  return this.$page.props.app.tenant.id == 1
}

export function truncate(text, length) {
  if (text?.length > length) {
    return text.substring(0, length) + '...'
  }
  return text
}
