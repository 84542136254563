<template>
  <button
    :disabled="isButtonDisabled"
    class="flex items-center"
    :class="[isTenantWorksite ? 'btn-red-gradient' : 'tenant-btn-1', disabled ? 'disabled' : '']"
    @click="(e) => $emit('click', e)"
  >
    <div v-if="loading" :class="'mr-2 ' + spinnerColor" />
    <slot />
  </button>
</template>

<script>
import { isTenantWorksite } from '@/Utils/Helpers'

export default {
  props: {
    spinnerColor: {
      type: String,
      default: 'spinner',
    },
    loading: Boolean,
    disabled: Boolean,
  },
  emits: ['click'],
  computed: {
    isTenantWorksite,
    isButtonDisabled() {
      return this.loading || this.disabled
    },
  },
}
</script>
