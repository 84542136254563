<template>
  <div>
    <label>
      <span v-if="label" class="form-label text-ts-front-label">{{ label }}</span>
      <input
        :id="id"
        ref="input"
        v-bind="$attrs"
        class="w-full rounded-lg border bg-ts-front-field-light p-3 text-ts-front-label focus:text-gray-800 focus:outline-none"
        :class="
          error ? 'border-ts-front-border-error' : 'border-ts-front-border-light focus:border-ts-front-border-dark'
        "
        :type="type ? type : 'text'"
        :value="modelValue"
        :autocomplete="autocomplete ? autocomplete : 'on'"
        @change="$emit('update:modelValue', $event.target.value)"
        @input="$emit('input', $event.target.value)"
      />
    </label>
    <div v-if="error" class="form-error text-ts-front-label-error">{{ error }}</div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    id: String,
    modelValue: [String, Number],
    label: String,
    error: String,
    type: String,
    autocomplete: String,
  },
  emits: ['update:modelValue', 'input'],
  methods: {
    focus() {
      this.$refs.input.focus()
    },
    select() {
      this.$refs.input.select()
    },
    setSelectionRange(start, end) {
      this.$refs.input.setSelectionRange(start, end)
    },
  },
}
</script>
