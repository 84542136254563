export const FormatTypeEnum = Object.freeze({
  NUMBER: 'Number',
  TEMPERATURE: 'Temperature',
})

export const TempRangeEnum = Object.freeze({
  BETWEEN: 'Between',
  GREATER_THAN_OR_EQUAL_TO: 'Greater than or equal to',
  LESS_THAN_OR_EQUAL_TO: 'Less than or equal to',
})

export const TempUnitEnum = Object.freeze({
  C: '\u2103',
  F: '\u2109',
})

export const FormQuestionOptions = [
  {
    id: 1,
    name: 'Text',
    type: 'form-text',
    component: 'FormText',
    icon: 'EllipsisHorizontalIcon',
  },
  {
    id: 2,
    name: 'Number',
    type: 'form-number',
    component: 'FormNumber',
    icon: 'HashtagIcon',
  },
  {
    id: 3,
    name: 'Checkbox',
    type: 'form-checkbox',
    component: 'FormCheckbox',
    icon: 'CheckIcon',
  },
  {
    id: 4,
    name: 'Date & Time',
    type: 'form-date-time',
    component: 'FormDateTime',
    icon: 'CalendarIcon',
  },
  {
    id: 5,
    name: 'Media',
    type: 'form-media',
    component: 'FormMedia',
    icon: 'PhotoIcon',
  },
  {
    id: 6,
    name: 'Slider',
    type: 'form-slider',
    component: 'FormSlider',
    icon: 'AdjustmentsHorizontalIcon',
  },
  {
    id: 7,
    name: 'Document Number',
    type: 'form-document-number',
    component: 'FormDocumentNumber',
    icon: 'CheckIcon',
  },
  {
    id: 8,
    name: 'Person',
    type: 'form-person',
    component: 'FormPerson',
    icon: 'UserIcon',
  },
  {
    id: 9,
    name: 'Location',
    type: 'form-location',
    component: 'FormLocation',
    icon: 'MapPinIcon',
  },
  {
    id: 10,
    name: 'Select',
    type: 'form-select',
    component: 'FormSelect',
    icon: 'CheckIcon',
  },
]
