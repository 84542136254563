<template>
  <a
    class="block capitalize text-ts-blue-500 no-underline hover:text-ts-blue-600 hover:underline"
    :class="{ 'border-t border-gray-200': i > 0 }"
    :href="route('front.training-records.download', [trainingRecord])"
  >
    <icon name="download" class="h-8 w-8 text-sm text-ts-blue-500" />
  </a>
</template>
<script>
export default {
  props: {
    trainingRecord: {
      type: Object,
      required: true,
    },
  },
}
</script>
