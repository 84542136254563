<template>
  <Disclosure v-if="item.type === 'FormSection'" v-slot="{ open }" as="div">
    <DisclosureButton class="mb-3 w-full columns-1 gap-8 rounded-lg bg-qualify-red-500 px-4 py-2">
      <div class="inline-flex w-full items-center rounded bg-qualify-red-500 text-white">
        <ChevronRightIcon :class="!open ? 'h-4 w-4 transition' : 'h-4 w-4 rotate-90 transition'" aria-hidden="true" />
        <p class="ml-2 py-2">{{ item.title }}</p>
      </div>
    </DisclosureButton>
    <DisclosurePanel as="dd">
      <front-form-item-mobile-view v-for="subItem in item.children" :key="subItem.uuid" :item="subItem" />
    </DisclosurePanel>
  </Disclosure>
  <div v-else class="mb-3 columns-1 gap-8 rounded-lg border border-ts-gray-500 bg-white px-4 py-2">
    <div class="mb-4">
      <component :is="itemComponentName" :item="item" :error="error" />
    </div>
    <p
      v-if="item.notes && !item.notesClicked"
      class="mt-2 cursor-pointer px-1 py-2 hover:rounded hover:bg-gray-100"
      @click="item.notesClicked = true"
    >
      {{ item.notes }}
    </p>
    <div v-if="item.notesClicked" class="py-2">
      <front-textarea-input
        :model-value="item.notes"
        :rows="3"
        :autosize="true"
        :placeholder="$t('Type your notes...')"
        class="m-0 mb-4 rounded border bg-white px-2 py-2 placeholder-shown:ring-0 focus:border-0 focus:outline-none focus:ring-2 focus:ring-qualify-red-600"
      />
      <div class="flex items-center justify-between">
        <div>
          <button
            type="button"
            class="mr-2 inline-flex items-center rounded-md bg-qualify-red-500 px-3 py-2 text-sm font-semibold leading-4 text-white shadow-sm focus:outline-none"
            @click="item.notesClicked = false"
          >
            Save
          </button>
          <button
            type="button"
            class="mr-2 inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-semibold leading-4 text-qualify-red-500 shadow-sm hover:bg-gray-50 focus:outline-none"
            @click="item.notesClicked = false"
          >
            Cancel
          </button>
        </div>
        <TrashIcon
          v-if="item.notes"
          class="h-5 w-5 cursor-pointer text-gray-500"
          aria-hidden="true"
          @click="handleDeleteNotes(item)"
        />
      </div>
    </div>
    <div class="py-2">
      <ul class="inline-flex text-qualify-red-500">
        <li class="mr-5">
          <button @click="item.notesClicked = true">
            <span class="flex items-center text-sm">
              <PencilSquareIcon class="mr-1 h-5 w-5" aria-hidden="true" />
              <span class="block truncate">Notes</span>
            </span>
          </button>
        </li>
        <li class="mr-5">
          <button>
            <span class="flex items-center text-sm">
              <PhotoIcon class="mr-1 h-5 w-5" aria-hidden="true" />
              <span class="block truncate">Media</span>
            </span>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import MobileAnswerableEnum from '@/Enums/MobileAnswerableEnum'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { ChevronRightIcon, PencilSquareIcon, PhotoIcon, TrashIcon } from '@heroicons/vue/24/outline'

export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    PencilSquareIcon,
    ChevronRightIcon,
    PhotoIcon,
    TrashIcon,
  },
  props: {
    item: Object,
  },
  data() {
    return {}
  },
  computed: {
    itemComponentName: function () {
      return MobileAnswerableEnum[this.item.type]
    },
  },
  methods: {
    handleSaveNotes(item) {
      item.notesClicked = false
    },
    handleDeleteNotes(item) {
      item.notesClicked = false
      item.notes = null
    },
  },
}
</script>
