const MobileAnswerableEnum = Object.freeze({
  'form-checkbox': 'CheckboxMobileAnswerField',
  'form-date-time': 'DateTimeMobileAnswerField',
  'form-document-number': 'DocumentNumberMobileAnswerField',
  'form-person': 'PersonMobileAnswerField',
  'form-location': 'LocationMobileAnswerField',
  'form-media': 'MediaMobileAnswerField',
  'form-number': 'NumberMobileAnswerField',
  'form-select': 'SelectMobileAnswerField',
  'form-slider': 'SliderMobileAnswerField',
  'form-text': 'TextMobileAnswerField',
})

export default MobileAnswerableEnum
