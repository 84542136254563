<template>
  <div class="w-full overflow-hidden rounded-lg border border-ts-gray-500 bg-white">
    <tab-menu :menu-list="menuList" />
    <div><slot /></div>
  </div>
</template>

<script>
export default {
  props: {
    menuList: Array,
  },
}
</script>
