<template>
  <div>
    <div class="w-24 h-24 rounded-md overflow-hidden bg-white relative group">
      <img v-if="imageUrl" :src="imageUrl" class="w-full h-full object-cover" :alt="file.name" />
      <div v-else class="w-full h-full bg-qualify-red-900 text-white border-gray-500 p-3">
        <div ref="imageNameIcon" class="w-full h-full overflow-hidden text-xs">{{ file.name }}</div>
      </div>
      <TrashIcon
        class="rounded-full text-white fill-current bg-red-700 bg-opacity-50 w-8 h-8 p-2 absolute top-0 right-0 m-1 hover:bg-red-500"
        @click="$emit('delete', file)"
      />
    </div>
    <div v-if="error" class="form-error text-xs w-full">{{ error }}</div>
  </div>
</template>
<script>
import { TrashIcon } from '@heroicons/vue/24/solid'
export default {
  components: { TrashIcon },
  props: {
    file: {
      type: Object,
      required: true,
    },
    error: String,
  },
  emits: ['delete'],
  data() {
    return {
      imageUrl: null,
    }
  },
  mounted() {
    if (this.file.type.startsWith('image')) {
      let reader = new FileReader()
      reader.onload = () => {
        this.imageUrl = reader.result
      }

      reader.readAsDataURL(this.file)
    }
    this.imageUrl = null
  },
}
</script>
