<template>
  <canvas ref="canvas" class="w-full" :class="{ hidden: !loaded }" />
</template>

<script>
import _debounce from 'lodash/debounce'

export default {
  props: {
    crop: Number,
    pdfFields: {
      type: Object,
      required: false,
      nullable: true,
    },
    pdfImages: {
      type: Object,
      required: false,
      nullable: true,
    },
    pdfSettings: Object,
    backgroundUrl: String,
    isEdit: Boolean,
  },
  data() {
    return {
      canvas: null,
      loaded: false,
    }
  },
  watch: {
    $props: {
      handler: _debounce(function () {
        this.loadCanvas()
      }, 500),
      deep: true,
    },
  },
  mounted() {
    // Setting up canvas.
    var canvas = this.$refs.canvas
    var ctx = canvas.getContext('2d')
    this.canvas = ctx

    // Setting canvas dimensions based on background image.
    var comp = this
    var img = new Image()
    img.onload = function () {
      canvas.width = this.width - comp.crop * 2
      canvas.height = this.height - comp.crop * 2

      comp.loadCanvas()
    }
    img.src = this.backgroundUrl
  },
  methods: {
    loadCanvas() {
      this.canvas.clearRect(0, 0, this.canvas.canvas.width, this.canvas.canvas.height)
      this.canvas.textBaseline = 'top'

      var imageObj = new Image()
      imageObj.onload = () => {
        this.canvas.drawImage(imageObj, -this.crop, -this.crop)
        this.plotValues()
      }
      imageObj.src = this.backgroundUrl

      this.loaded = true
    },
    plotValues() {
      Object.keys(this.pdfSettings).forEach((pdfSettingKey) => {
        if (!this.pdfSettings[pdfSettingKey].x_position && !this.pdfSettings[pdfSettingKey].y_position) {
          return
        }

        this.setFont(pdfSettingKey)

        if (this.pdfFields && pdfSettingKey in this.pdfFields) {
          this.plotText(pdfSettingKey)
        } else if (this.pdfImages && pdfSettingKey in this.pdfImages && this.pdfImages[pdfSettingKey]) {
          this.plotImage(pdfSettingKey, this.pdfImages[pdfSettingKey])
        } else if (this.isEdit) {
          this.plotFieldHightLight(pdfSettingKey)
        }
      })
    },
    plotText(pdfSettingKey) {
      const textValue = this.pdfFields[pdfSettingKey] ?? ''

      if (!this.pdfSettings[pdfSettingKey].width) {
        this.canvas.fillText(
          textValue,
          this.pdfSettings[pdfSettingKey].x_position,
          this.pdfSettings[pdfSettingKey].y_position
        )
      } else {
        this.canvas.fillText(
          textValue,
          this.pdfSettings[pdfSettingKey].x_position,
          this.pdfSettings[pdfSettingKey].y_position,
          this.pdfSettings[pdfSettingKey].width
        )
      }
    },
    plotImage(pdfSettingKey, imageUrl) {
      var image = new Image()
      image.onload = () => {
        let ratio = image.width / image.height
        var newHeight = this.pdfSettings[pdfSettingKey].height
        var newWidth = newHeight * ratio
        if (newWidth > this.pdfSettings[pdfSettingKey].width) {
          newWidth = this.pdfSettings[pdfSettingKey].width
          newHeight = newWidth / ratio
        }
        this.canvas.drawImage(
          image,
          this.pdfSettings[pdfSettingKey].x_position,
          this.pdfSettings[pdfSettingKey].y_position,
          newWidth,
          newHeight
        )
      }
      image.src = imageUrl
    },
    plotFieldHightLight(pdfSettingKey) {
      const textValue = pdfSettingKey

      if (this.pdfSettings[pdfSettingKey].width && this.pdfSettings[pdfSettingKey].height) {
        this.canvas.fillStyle = 'yellow'

        this.canvas.fillRect(
          this.pdfSettings[pdfSettingKey].x_position,
          this.pdfSettings[pdfSettingKey].y_position,
          this.pdfSettings[pdfSettingKey].width,
          this.pdfSettings[pdfSettingKey].height
        )
        this.canvas.fillStyle = '#2c2c2c'
      }

      if (!this.pdfSettings[pdfSettingKey].width) {
        this.canvas.fillText(
          textValue,
          this.pdfSettings[pdfSettingKey].x_position,
          this.pdfSettings[pdfSettingKey].y_position
        )
      } else {
        this.canvas.fillText(
          textValue,
          this.pdfSettings[pdfSettingKey].x_position,
          this.pdfSettings[pdfSettingKey].y_position,
          this.pdfSettings[pdfSettingKey].width
        )
      }
    },
    setFont(pdfSettingKey) {
      const fontSize = (this.canvas.font = this.pdfSettings[pdfSettingKey].text_size ?? 20)

      this.canvas.font = `${fontSize}px Arial`
      this.canvas.fillStyle = '#2c2c2c'
    },
  },
}
</script>
