<template>
  <p v-if="modelValue?.scope_level == SCOPE_LEVEL.ORGANIZATION">{{ modelValue.name }}</p>
  <div v-else-if="modelValue?.scope_level" class="inline-block">
    <template v-for="choice in modelValue.choices" :key="choice.id">
      <option-tag :model-value="choice" />
    </template>
  </div>
</template>
<script>
import SelectScope from '@/Enums/SelectScope.js'
import OptionTag from '@/Shared/Answerables/OptionTag.vue'
export default {
  components: {
    OptionTag,
  },
  props: {
    modelValue: Object,
  },
  data() {
    return {
      SCOPE_LEVEL: SelectScope,
    }
  },
  methods: {},
}
</script>
