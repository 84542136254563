<template>
  <table class="w-full">
    <thead>
      <tr>
        <slot name="head" />
      </tr>
    </thead>
    <tbody>
      <slot />
    </tbody>
  </table>
</template>
<script>
export default {
  props: {
    headings: Array,
  },
}
</script>
