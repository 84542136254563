<template>
  <answer-view :label="formAnswer.form_content.title">
    <div>
      <option-tag v-for="selectedValue in modelValue" :key="selectedValue.id" :model-value="selectedValue" />
    </div>
  </answer-view>
</template>
<script>
import OptionTag from '@/Shared/Answerables/OptionTag.vue'
export default {
  components: {
    OptionTag,
  },
  props: {
    modelValue: Object,
    formAnswer: Object,
  },
}
</script>
