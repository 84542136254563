<template>
  <div>
    <div class="my-6">
      <span v-if="formAnswer.form_content.is_required" class="text-red-700">*</span>
      <span>{{ formAnswer.form_content.title }}</span>
    </div>
    <div class="flex items-center">
      <div class="w-full">
        <front-textarea-input
          v-model="value"
          class="grow"
          :required="formAnswer.form_content.is_required"
          @change="change"
        />
      </div>
      <button class="ml-2 rounded-md bg-qualify-red-500 px-3 py-4 font-semibold text-white" @click="show">
        <span class="inline-flex items-center">
          <icon name="location" class="mr-1 h-4 w-4 flex-shrink-0 fill-white" />
          {{ $t('Map') }}
        </span>
      </button>
    </div>

    <front-modal :show="showMapModal" class="rounded text-base" width="auto" @close="close">
      <div class="flex flex-wrap bg-white px-8 pt-8">
        <breadcrumb :name="$t('Select location')" />
      </div>
      <div class="max-w-sm overflow-hidden rounded bg-white px-8 pt-4 shadow">
        <div class="flex items-center">
          <div class="mb-4 w-96">
            <google-maps-autocomplete
              v-model="fullAddress"
              :placeholder="$t('Look for an Address')"
              :show-map="true"
              :show-location="true"
            />
          </div>
        </div>
        <div class="flex items-center justify-center bg-white px-8 pb-8 pt-1">
          <front-secondary-button type="button" @click="close">{{ $t('Close') }}</front-secondary-button>
          <front-loading-button :disabled="!fullAddress" :loading="false" class="ml-4" type="button" @click="save">
            {{ $t('Save') }}
          </front-loading-button>
        </div>
      </div>
    </front-modal>
  </div>
</template>
<script>
import FrontTextareaInput from '../FrontTextareaInput.vue'

export default {
  components: {
    FrontTextareaInput,
  },
  props: {
    modelValue: String,
    formAnswer: Object,
    error: [Object, String],
  },
  emits: ['update:modelValue'],
  data() {
    return {
      value: this.modelValue ?? null,
      showMapModal: false,
      fullAddress: null,
    }
  },
  methods: {
    change() {
      this.$emit('update:modelValue', this.value)
    },
    show() {
      this.showMapModal = true
    },
    close() {
      this.showMapModal = false
    },
    save() {
      this.showMapModal = false
      this.value =
        (this.fullAddress.street_number || '') +
        ' ' +
        (this.fullAddress.route || '') +
        ', ' +
        (this.fullAddress.locality || '') +
        ', ' +
        (this.fullAddress.administrative_area_level_1 || '') +
        ', ' +
        (this.fullAddress.country || '') +
        ' (' +
        this.fullAddress.latitude +
        ', ' +
        this.fullAddress.longitude +
        ')'
      this.change()
    },
  },
}
</script>
