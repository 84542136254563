<template>
  <div class="max-w-xs truncate leading-normal" :title="text">{{ text }}</div>
</template>

<script>
export default {
  props: {
    text: String,
  },
}
</script>
