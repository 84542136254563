<template>
  <div>
    <label>
      <span v-if="label" class="form-label">
        <span>{{ label }}</span>
        <span v-if="required" class="text-red-500">*</span>
        <span>:</span>
      </span>
      <input
        ref="input"
        v-bind="$attrs"
        autocomplete="off"
        class="form-input"
        :class="{ error: error }"
        type="text"
        :value="modelValue"
        :required="required"
        @change="change"
        @keyup="change"
      />
    </label>
    <div v-if="error" class="form-error">{{ error }}</div>
  </div>
</template>

<script>
import moment from 'moment'
import pikaday from 'pikaday'

export default {
  inheritAttrs: false,
  props: {
    modelValue: String,
    label: String,
    error: String,
    format: {
      type: String,
      default: 'MMMM D, YYYY',
    },
    minYear: {
      type: Number,
      default: 2019,
    },
    maxYear: {
      type: Number,
      default: new Date(new Date().getFullYear() + 1, 0, 1).getFullYear(),
    },
    defaultYear: {
      type: Number,
      default: new Date().getFullYear(),
    },
    defaultMonth: {
      type: Number,
      default: new Date().getMonth() + 1,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  mounted() {
    let picker = new pikaday({
      format: this.format,
      reposition: false,
      position: 'bottom left',
      field: this.$refs.input,
      yearRange: [this.minYear, this.maxYear],
      theme: 'date-input',
      keyboardInput: false,
      i18n: {
        previousMonth: 'Prev',
        nextMonth: 'Next',
        months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        weekdaysShort: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      },
    })

    if (this.modelValue) {
      picker.gotoYear(moment(this.modelValue, this.format).year())
      picker.gotoMonth(moment(this.modelValue, this.format).month())
    }
  },
  methods: {
    focus() {
      this.$refs.input.focus()
    },
    select() {
      this.$refs.input.select()
    },
    change(e) {
      this.$emit('update:modelValue', e.target.value)
    },
  },
}
</script>
<style scoped>
@import 'pikaday/css/pikaday.css';
</style>
