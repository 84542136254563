<template>
  <div class="bg-ts-front-bg">
    <head>
      <title>{{ pageTitle }}</title>
    </head>
    <div id="dropdown-port" name="dropdown" slim />
    <div class="flex flex-col">
      <div class="flex min-h-screen flex-col">
        <div class="no-print md:flex">
          <div
            class="flex items-center justify-between px-6 py-4 md:w-56 md:flex-shrink-0"
            :class="isTenantWorksite ? 'bg-qualify-red-500' : 'bg-blue-700'"
          >
            <inertia-link href="/">
              <img v-if="accountLogo" id="custom-tenant-logo" :src="accountLogo" alt="Account Logo" />
              <front-qualify v-else class="mt-1 block md:ml-5" width="138.6" height="36.4" />
            </inertia-link>
            <div class="flex items-center">
              <div v-if="localeSwitchingEnabled" class="mr-2 md:hidden">
                <locale-select-input>
                  <option v-for="(locale, key) in $page.props.locales" :key="key" :value="locale">{{ key }}</option>
                </locale-select-input>
              </div>
              <notification-count class="md:hidden" />
              <div
                class="hamburger hamburger--squeeze inline-block md:hidden"
                :class="{ 'is-active': open }"
                style="z-index: 99999"
                @click="open = !open"
              >
                <div class="hamburger-box">
                  <div class="hamburger-inner" />
                </div>
              </div>
              <transition
                enter-active-class="transition ease-out origin-top duration-200"
                leave-active-class="transition origin-top ease-in duration-100"
                enter-class="opacity-0 transform scale-90"
                enter-to-class="opacity-100 transform scale-100"
                leave-class="opacity-100 transform scale-100"
                leave-to-class="opacity-0 transform scale-90"
              >
                <div v-if="open" class="absolute inset-x-0 top-0 bg-white" style="z-index: 99998">
                  <div class="my-16 ml-3 flex flex-col md:ml-0 md:items-center">
                    <inertia-link :href="route('front.settings')" class="mb-6 ml-5 md:hidden">
                      <div
                        class="group inline-block w-auto cursor-pointer select-none rounded-full bg-qualify-red-500 p-2"
                      >
                        <div class="avatar__menu flex items-center">
                          <div class="mr-3">
                            <img
                              v-if="$page.props.auth.user.image_url"
                              class="h-8 w-8 rounded-full object-cover"
                              :src="$page.props.auth.user.image_url"
                              alt="avatar"
                              style="box-shadow: inset 0 0 0 1px hsla(0, 0%, 0%, 0.1)"
                            />
                            <div v-else class="flex h-8 w-8 items-center justify-center rounded-full bg-gray-400">
                              <div class="text-lg font-bold tracking-wider text-white">
                                {{ initial }}
                              </div>
                            </div>
                          </div>
                          <div class="mr-2 whitespace-nowrap text-lg font-semibold text-white">
                            <span>{{ $page.props.auth.user.first_name }}</span>
                          </div>
                        </div>
                      </div>
                    </inertia-link>
                    <front-manager-menu v-if="isUrl('account/manager*')" />
                    <front-admin-menu v-else-if="isUrl('account/admin*')" />
                    <front-user-menu v-else />
                  </div>
                </div>
              </transition>
            </div>
          </div>
          <div class="md:hidden">
            <front-role-toggle @open:organizationModal="openOrganizationModal" />
          </div>
          <div
            class="hidden w-full items-center justify-between p-4 text-sm md:flex md:px-12 md:py-6 md:text-base"
            :class="isTenantWorksite ? 'bg-qualify-red-500' : 'bg-blue-700'"
          >
            <div>&#x0200B;</div>
            <front-role-toggle @open:organizationModal="openOrganizationModal" />
            <div v-if="$page.props.auth" class="flex items-center">
              <div v-if="localeSwitchingEnabled" class="mr-3">
                <locale-select-input>
                  <option v-for="(locale, key) in $page.props.locales" :key="key" :value="locale">{{ key }}</option>
                </locale-select-input>
              </div>
              <notification-count />
              <hover-dropdown
                class="group flex cursor-pointer select-none items-center rounded-full p-2"
                :class="
                  isTenantWorksite
                    ? 'bg-ts-red-600 hover:bg-ts-red-500 focus:bg-ts-red-500'
                    : 'bg-blue-800 hover:bg-blue-600 focus:bg-blue-600'
                "
                placement="top-0 right-0"
              >
                <div class="flex items-center max-w-10">
                  <div class="mr-3">
                    <img
                      v-if="$page.props.auth.user.image_url"
                      class="h-8 w-8 rounded-full object-cover"
                      :src="$page.props.auth.user.image_url"
                      alt="avatar"
                      style="box-shadow: inset 0 0 0 1px hsla(0, 0%, 0%, 0.1)"
                    />
                    <div v-else class="flex h-8 w-8 items-center justify-center rounded-full bg-gray-400 text-gray-100">
                      <div class="text-md font-bold tracking-wider">{{ initial }}</div>
                    </div>
                  </div>
                  <div class="mr-2 whitespace-nowrap font-semibold text-white overflow-hidden text-ellipsis">
                    <span>{{ name }}</span>
                  </div>
                </div>
                <template #dropdown>
                  <div class="mt-2 bg-white shadow-lg rounded-lg overflow-hidden flex flex-col w-full">
                    <inertia-link
                      :href="route('front.settings')"
                      class="flex items-center px-8 py-2 first:pt-3 last:pb-3 hover:bg-ts-red-500 focus:bg-ts-red-500 hover:text-white focus:text-white"
                    >
                      <icon name="setting" class="mr-3 h-5 w-5" />
                      <span>Settings</span>
                    </inertia-link>
                    <inertia-link
                      :href="route('front.logout')"
                      class="flex items-center px-8 py-2 first:pt-3 last:pb-3 hover:bg-ts-red-500 focus:bg-ts-red-500 hover:text-white focus:text-white"
                    >
                      <icon name="logout" class="mr-3 h-5 w-5" />
                      <span>Logout</span>
                    </inertia-link>
                  </div>
                </template>
              </hover-dropdown>
            </div>
            <div v-else>
              <inertia-link
                class="border-2 border-white px-4 py-2 font-heading font-semibold uppercase text-white"
                :href="route('front.login')"
              >
                {{ $t('Log in') }}
              </inertia-link>
            </div>
          </div>
        </div>
        <div class="flex flex-grow">
          <div class="no-print hidden w-56 flex-shrink-0 bg-white pt-12 md:block">
            <div class="flex-col justify-between">
              <front-manager-menu v-if="isUrl('account/manager*')" />
              <front-admin-menu v-else-if="isUrl('account/admin*')" />
              <front-user-menu v-else />
              <div class="org__create">
                <div v-if="!$page.props.auth.user.organization" class="flex w-full flex-col justify-center p-4 pt-12">
                  <img src="/images/organization-icon.svg" class="mx-auto my-2 w-32" />
                  <h3 class="my-2 mb-2 text-center text-sm font-semibold leading-5 lg:mb-0">
                    {{ $t('Need to Manage Training for your Team?') }}
                  </h3>
                  <div class="my-2 rounded-md bg-ts-front-bg p-3 text-center text-sm leading-5">
                    {{ $t('Management tools for training assignment and certification tracking.') }}
                  </div>
                  <button
                    class="hover:text-qualify-red active:text-qualify-red my-2 px-4 text-center font-heading text-sm hover:underline active:underline"
                    @click="showOrganizationCreateModal = true"
                  >
                    {{ $t('+ Add Organization') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full overflow-hidden px-4 py-8 md:px-20 md:py-16">
            <div
              v-if="$page.props.flash.success && showFlash"
              class="no-print mb-8 flex max-w-lg items-center justify-between rounded bg-green-400"
            >
              <div class="flex items-center">
                <icon name="checkmark" class="ml-4 mr-2 h-4 w-4 flex-shrink-0 fill-white" />
                <div class="py-4 text-sm font-medium text-white">{{ $page.props.flash.success }}</div>
              </div>
              <button type="button" class="group mr-2 p-2" @click="showFlash = false">
                <icon class="block h-3 w-3 fill-green-300 group-hover:fill-green-100" name="close" />
              </button>
            </div>
            <div
              v-if="$page.props.flash.error && showFlash"
              class="no-print mb-8 flex max-w-lg items-center justify-between rounded bg-red-400"
            >
              <div class="flex items-center">
                <icon name="close-outline" class="ml-4 mr-2 h-4 w-4 flex-shrink-0 fill-white" />
                <div class="py-4 text-sm font-medium text-white">{{ $page.props.flash.error }}</div>
              </div>
              <button type="button" class="group mr-2 p-2" @click="showFlash = false">
                <icon class="block h-3 w-3 fill-red-300 group-hover:fill-red-100" name="close" />
              </button>
            </div>
            <div
              v-if="$page.props.errors.length > 0 && showFlash"
              class="no-print mb-8 flex max-w-lg items-center justify-between rounded bg-red-400"
            >
              <div class="flex items-center">
                <icon name="close-outline" class="ml-4 mr-2 h-4 w-4 flex-shrink-0 fill-white" />
                <div class="py-4 text-sm font-medium text-white">
                  <span v-if="$page.props.errors.length === 1">{{ $t('There is one form error.') }}</span>
                  <span v-else>{{ $t('There are :length form errors.', { length: $page.props.errors.length }) }}</span>
                </div>
              </div>
              <button type="button" class="group mr-2 p-2" @click="showFlash = false">
                <icon class="block h-3 w-3 fill-red-300 group-hover:fill-red-100" name="close" />
              </button>
            </div>
            <div v-if="subscriptionBanner">
              <subscription-banner :banner="subscriptionBanner" />
            </div>
            <slot />
          </div>
        </div>
      </div>
    </div>
    <div v-if="$page.props.isImpersonating" class="no-print fixed bottom-0 left-0 mb-5 ml-8">
      <front-button-link :href="route('stop-impersonate')" as="button" type="button" class="btn-red">
        {{ $t('Stop Impersonating') }}
      </front-button-link>
    </div>
    <front-organization-create-modal :show="showOrganizationCreateModal" @close="showOrganizationCreateModal = false" />
  </div>
</template>
<script>
import setTimezone from '@/mixins/setTimezoneForUser.js'
import zendesk from '@/mixins/zendesk.js'
import { isNotUrl, isTenantWorksite, isUrl, logoName } from '@/Utils/Helpers'
import FrontOrganizationCreateModal from './FrontOrganizationCreateModal.vue'
import FrontRoleToggle from './FrontRoleToggle.vue'
import NotificationCount from './NotificationCount.vue'

export default {
  components: { FrontRoleToggle, FrontOrganizationCreateModal, NotificationCount },
  mixins: [zendesk, setTimezone],
  props: {
    title: String,
  },
  data() {
    return {
      showFlash: true,
      open: false,
      showOrganizationCreateModal: false,
      accountLogo: this.$page.props.app.tenant.logo,
    }
  },
  computed: {
    isTenantWorksite,
    localeSwitchingEnabled() {
      return this.$page.props.app.tenant.locale_en_enabled && this.$page.props.app.tenant.locale_fr_enabled
    },
    showAdminMenu() {
      return (
        this.$page.props.auth.user.is_instructor ||
        this.$page.props.auth.user.is_admin ||
        this.$page.props.auth.user.is_support ||
        this.$page.props.auth.user.is_super
      )
    },
    initial() {
      return this.$page.props.auth.user.display_name.charAt(0)
    },
    name() {
      return this.$page.props.auth.user.display_name.split(' ')[0]
    },
    pageTitle() {
      return this.title ? `${this.title} | ${this.$page.props.app.name}` : this.$page.props.app.name
    },
    subscriptionBanner() {
      const routeName = this.$page.props.routeName
      return this.$page.props.auth.user.organization?.subscription_banners?.find((banner) =>
        banner.show_on_page_routes?.find((route) => route === routeName)
      )
    },
  },
  watch: {
    '$page.props.flash': {
      handler() {
        this.showFlash = true
      },
      deep: true,
    },
  },
  mounted() {
    // Auth Check Zendesk Chat Widget
    this.zE_auth()
    this.setTimezone()
  },
  methods: {
    logoName,
    isUrl,
    isNotUrl,
    openOrganizationModal() {
      this.showOrganizationCreateModal = true
    },
  },
}
</script>

<style scoped>
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #dd163d;
}

#custom-tenant-logo {
  max-width: 176px;
  max-height: 46.23px;
}
</style>
