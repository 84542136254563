<template>
  <button type="button" class="flex flex-col" @mouseover="show" @mouseleave="hide" @focus="onFocus" @blur="onBlur">
    <slot />
    <div class="relative w-full">
      <div v-show="isShow" ref="dropdown" class="absolute mt-1" :class="placement" style="z-index: 90">
        <slot name="dropdown" />
      </div>
    </div>
  </button>
</template>

<script>
export default {
  props: {
    placement: {
      type: String,
      default: 'top-0 left-0',
    },
  },
  data() {
    return {
      isShow: false,
    }
  },
  methods: {
    show() {
      this.isShow = true
    },
    hide() {
      this.isShow = false
    },
    onFocus() {
      this.isShow = true
    },
    onBlur() {
      this.isShow = false
    },
  },
}
</script>
