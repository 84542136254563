<template>
  <button :class="isTenantWorksite ? 'btn-red-gradient' : 'tenant-btn-1'" @click="(e) => $emit('click', e)">
    <slot />
  </button>
</template>

<script>
import { isTenantWorksite } from '@/Utils/Helpers'

export default {
  emits: ['click'],
  computed: {
    isTenantWorksite,
  },
}
</script>
