<template>
  <a
    v-if="$page.props.app.tenant.email"
    :href="`mailto:${$page.props.app.tenant.email}`"
    class="relative group -ml-3 flex items-center whitespace-nowrap text-lg text-ts-gray-text-bold focus:text-white hover:text-white"
    :class="{
      'hover:bg-qualify-red-500 focus:bg-qualify-red-500': isTenantWorksite,
      'hover:bg-blue-600 focus:bg-blue-600': !isTenantWorksite,
    }"
  >
    <div class="ml-10 py-2 flex items-center">
      <icon name="email" class="mr-3 h-5 w-5" color="currentColor" stroke-width="2" />
      <span>
        {{ $t('Send an Email') }}
      </span>
    </div>
  </a>
</template>
<script>
import { isTenantWorksite } from '@/Utils/Helpers'
export default {
  computed: {
    isTenantWorksite,
  },
}
</script>
