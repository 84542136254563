<template>
  <front-modal :show="show" class="rounded text-base" width="auto" @close="$emit('cancel')">
    <div class="max-w-md overflow-hidden rounded bg-white p-6 shadow md:p-10 lg:p-12">
      <div class="mb-4 text-center">
        <h1 class="mt-4 text-3xl font-bold">
          <slot name="title" />
        </h1>
      </div>
      <div class="py-4">
        <slot name="content" />
      </div>
      <div class="flex items-center justify-center bg-white px-2 pt-8">
        <front-outline-button class="mx-2" type="button" @click="$emit('cancel')">
          <slot name="cancel-button">{{ $t('Cancel') }}</slot>
        </front-outline-button>
        <front-loading-button type="button" @click="$emit('confirm')">
          <slot name="confirm-button">{{ $t('Confirm') }}</slot>
        </front-loading-button>
      </div>
    </div>
  </front-modal>
</template>

<script>
export default {
  props: {
    show: {
      required: true,
      type: Boolean,
    },
  },
  emits: ['confirm', 'cancel'],
}
</script>
