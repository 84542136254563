<template>
  <div v-if="finishedAt">
    <div v-if="passed" :class="`${cssClasses} bg-green-900 bg-opacity-8 text-green-900`">Passed</div>
    <div v-else :class="`${cssClasses} bg-red-900 bg-opacity-8 text-red-900`">Failed</div>
  </div>
</template>
<script>
export default {
  props: {
    passed: {
      type: Boolean,
    },
    finishedAt: {
      type: String,
    },
  },
  data() {
    return {
      cssClasses: 'inline-block px-1 py-1 rounded-full text-sm text-center font-semibold w-20',
    }
  },
}
</script>
