<template>
  <a
    :href="href"
    class="block px-4 py-2 text-sm capitalize text-gray-700 hover:text-white"
    :class="isTenantWorksite ? 'hover:bg-ts-red-500' : 'hover:bg-blue-700'"
    @click="(e) => $emit('click', e)"
  >
    <slot />
  </a>
</template>

<script>
import { isTenantWorksite } from '@/Utils/Helpers'

export default {
  props: {
    href: {
      type: String,
      default: '#',
    },
  },
  emits: ['click'],
  computed: {
    isTenantWorksite,
  },
}
</script>
