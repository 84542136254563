<template>
  <transition name="fade">
    <div v-show="show" class="fixed inset-0 z-50 flex h-screen w-full items-center justify-center">
      <div class="absolute h-screen w-full bg-black opacity-25" @click.self="close" />
      <div class="relative w-full shadow-lg" :style="{ width: width }">
        <button class="absolute right-0 top-0 mr-3 mt-3" @click.stop="close">
          <icon name="close" class="block h-3 w-3 fill-gray-400" />
        </button>
        <slot />
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    width: {
      required: true,
      type: String,
    },
  },
  watch: {
    show(value) {
      if (value) {
        return document.querySelector('body').classList.add('overflow-hidden')
      }
      document.querySelector('body').classList.remove('overflow-hidden')
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.4s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
