<template>
  <div class="p-2 rounded">
    <label class="flex items-center">
      <icon v-if="modelValue" name="checkmark" class="w-4 h-4" />
      <icon v-else name="close" class="w-4 h-4" />
      <span class="mx-2">{{ formAnswer.form_content.title }}</span>
    </label>
  </div>
</template>
<script>
import Icon from '../Icon.vue'

export default {
  components: {
    Icon,
  },
  props: {
    modelValue: Boolean,
    formAnswer: Object,
  },
}
</script>
