<template>
  <div v-if="enrolment.is_subscription_created" :class="`${cssClasses} bg-ts-green-200 text-ts-green-500`">
    {{ $t('Subscription') }}
  </div>
  <div v-else-if="enrolment.payment_status === 'Paid'" :class="`${cssClasses} bg-ts-green-200 text-ts-green-500`">
    {{ $t('Paid') }}
  </div>
  <div v-else-if="enrolment.payment_status === 'Pending'" :class="`${cssClasses} bg-ts-grey-100 text-ts-grey-500`">
    {{ $t('Pending') }}
  </div>
  <div v-else-if="enrolment.payment_status === 'Cancelled'" :class="`${cssClasses} bg-ts-red-100 text-ts-red-500`">
    {{ $t('Cancelled') }}
  </div>
</template>
<script>
export default {
  props: {
    enrolment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      cssClasses: 'inline-block px-2 py-3 rounded-xl text-sm text-center',
    }
  },
}
</script>
