window.GOOGLEMAPS_API_KEY = import.meta.env.VITE_GOOGLEMAPS_API_KEY

const CALLBACK_NAME = 'gmapsCallback'

let initialized = !!window.google
let resolveInitPromise
let rejectInitPromise

const initPromise = new Promise((resolve, reject) => {
  resolveInitPromise = resolve
  rejectInitPromise = reject
})

export default (options) => {
  if (initialized) return initPromise

  initialized = true

  window[CALLBACK_NAME] = () => resolveInitPromise(window.google)

  options = {
    key: GOOGLEMAPS_API_KEY,
    callback: CALLBACK_NAME,
    ...options,
  }

  let query = Object.keys(options)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(options[key]))
    .join('&')

  let script = document.createElement('script')
  script.async = true
  script.defer = true
  script.src = `https://maps.googleapis.com/maps/api/js?${query}`
  script.onerror = rejectInitPromise
  document.querySelector('head').appendChild(script)

  return initPromise
}
