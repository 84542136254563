<template>
  <worksite-dark v-if="name === 'dark app.worksite.ca'" />
  <worksite-white v-else-if="name === 'white app.worksite.ca'" />
  <qualify v-else />
</template>

<script>
export default {
  props: {
    name: String,
  },
}
</script>
