<template>
  <div>
    <div v-if="label" class="form-label">{{ label }}:</div>
    <div ref="input" v-bind="$attrs" :class="{ error: error }" class="flex space-x-6 rounded border p-3">
      <label v-for="(dataValue, key) in data" :key="key" class="inline-flex">
        <input
          v-model="value"
          type="radio"
          class="form-radio"
          :value="dataValue"
          :disabled="disabled"
          @input="$emit('update:modelValue', resolve($event.target.value))"
        />
        <span class="ml-2">{{ key }}</span>
      </label>
    </div>
    <div v-if="error" class="form-error">{{ error }}</div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    id: String,
    data: {
      type: Object,
      default: () => ({ Yes: true, No: false }),
    },
    resolve: {
      type: Function,
      default: (value) => value === 'true',
    },
    modelValue: [Boolean, String],
    label: String,
    error: String,
    disabled: Boolean,
  },
  emits: ['update:modelValue'],
  data() {
    return {
      value: this.modelValue,
    }
  },
  methods: {
    focus() {
      this.$refs.input.focus()
    },
  },
}
</script>
