<template>
  <div class="w-full">
    <breadcrumb class="hidden md:inline" :name="value" />
    <breadcrumb class="md:hidden" :name="value" />
    <div>
      <inertia-link
        v-if="backUrl"
        class="text-darkGray flex items-center px-3 md:px-0 pt-4 hover:font-semibold"
        :href="backUrl"
      >
        <icon name="arrow-left" class="block h-3 w-3 fill-current" />
        <span class="ml-1">{{ $t('Back') }}</span>
      </inertia-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
    backUrl: String,
  },
}
</script>
