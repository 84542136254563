<template>
  <Disclosure v-slot="{ open }" as="div">
    <div class="text-m relative flex flex-row items-center bg-white text-gray-500">
      <TrashIcon
        class="absolute -right-8 h-10 w-5 cursor-pointer text-gray-500"
        aria-hidden="true"
        @click="onRemoveSection"
      />
      <div class="basis-2/3">
        <div class="flex">
          <DisclosureButton v-if="itemCount" class="mt-3.5 flex text-gray-900">
            <div
              class="mx-3 flex h-6 w-6 flex-none items-center justify-center rounded-full bg-qualify-red-500 text-white"
            >
              <ChevronRightIcon
                :class="!open ? 'h-4 w-4 transition' : 'h-4 w-4 rotate-90 transition'"
                aria-hidden="true"
              />
            </div>
          </DisclosureButton>
          <Bars3Icon
            v-if="(itemCount && !open) || !itemCount"
            class="handle z-20 mx-3 my-3.5 flex h-6 w-6 flex-none cursor-move items-center justify-center text-gray-400"
            aria-hidden="true"
          />
          <div class="w-full">
            <front-text-input
              class="placeholder-shown:text-bold placeholder-shown:text-m m-0 mt-0 border-0 bg-white px-2 py-4 ring-0 ring-inset placeholder-shown:border-0 placeholder-shown:ring-0 focus:outline-none focus:ring-2 focus:ring-qualify-red-600"
              :placeholder="$t('Type section title')"
              @focus="question.isOptionVisible = true"
              @blur="question.isOptionVisible = false"
            />
            <div v-if="!itemCount" class="my-3 inline-flex cursor-pointer items-center" @click="addQuestionsToSection">
              <PlusIcon class="mr-2 h-4 w-4 text-qualify-red-500" aria-hidden="true" />
              <p class="text-sm text-qualify-red-500">Add questions inside this section</p>
            </div>
            <div v-if="itemCount && !open" class="mx-3 my-3 inline-flex items-center">
              <p class="text-sm text-gray-600">{{ itemCount }} item hidden</p>
            </div>
          </div>
        </div>
      </div>
      <div class="basis-1/3">
        <div class="flex items-center px-4">
          <input
            id="required"
            aria-describedby="required-description"
            name="required"
            type="checkbox"
            class="mr-4 h-4 w-4 rounded border-gray-600 text-gray-600 focus:ring-indigo-500"
          />
          <label for="required" class="text-sm text-gray-600">Repeat this section</label>
        </div>
      </div>
    </div>

    <DisclosurePanel class="ml-6" as="dd">
      <slot />
    </DisclosurePanel>
  </Disclosure>
</template>
<script>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { TrashIcon } from '@heroicons/vue/20/solid'
import { Bars3Icon, ChevronRightIcon, PlusIcon } from '@heroicons/vue/24/outline'

export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Bars3Icon,
    ChevronRightIcon,
    PlusIcon,
    TrashIcon,
  },
  props: {
    item: Object,
    itemCount: Number,
  },
  emits: ['add:questionToSection', 'remove:section'],
  data() {
    return {}
  },
  methods: {
    addQuestionsToSection() {
      this.$emit('add:questionToSection', { section: this.item })
    },
    onRemoveSection() {
      this.$emit('remove:section', this.item)
    },
  },
}
</script>
