<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 285.97 78.57">
    <defs />
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          class="cls-1"
          d="M77.07,40.39v-20a2.12,2.12,0,0,1,1.89-2h5.48a2.13,2.13,0,0,1,1.9,2V40.77c0,2.93,1,10,9.64,10s9.65-7.09,9.65-10V20.34a2.13,2.13,0,0,1,1.89-2H113a2.12,2.12,0,0,1,1.89,2V40.39c0,4.92-1.42,19.11-18.92,19.11S77.07,45.31,77.07,40.39Z"
        />
        <path
          class="cls-1"
          d="M156.65,20.34a2.13,2.13,0,0,1,1.89-2H164a2.13,2.13,0,0,1,1.89,2V56.47a2.14,2.14,0,0,1-1.89,2h-5.49a2.14,2.14,0,0,1-1.89-2V54.39c-5.58,5.11-11.26,5.11-14.57,5.11-11.25,0-20.62-9.46-20.62-21.1s9.37-21.09,20.62-21.09c3.31,0,9,0,14.57,5.11Zm0,18.06v-.47c-.28-7-6.05-11.92-13.05-11.92s-12.87,5.11-12.87,12.39,5.77,12.39,12.87,12.39S156.65,45.69,156.65,38.4Z"
        />
        <path
          class="cls-1"
          d="M185.62,56.47a2.13,2.13,0,0,1-1.89,2h-5.49a2.14,2.14,0,0,1-1.89-2V2.1a2.13,2.13,0,0,1,1.89-2h5.49a2.12,2.12,0,0,1,1.89,2Z"
        />
        <path
          class="cls-1"
          d="M201.32,3.69a5.65,5.65,0,0,1,5.58,5.58,5.55,5.55,0,0,1-5.58,5.39,5.45,5.45,0,0,1-5.39-5.39A5.55,5.55,0,0,1,201.32,3.69ZM206,56.47a2.14,2.14,0,0,1-1.89,2h-5.49a2.14,2.14,0,0,1-1.89-2V20.34a2.13,2.13,0,0,1,1.89-2h5.49a2.13,2.13,0,0,1,1.89,2Z"
        />
        <path
          class="cls-1"
          d="M230.86,27.05V56.47a2.14,2.14,0,0,1-1.89,2h-5.49a2.14,2.14,0,0,1-1.89-2V27.05h-6.72A2,2,0,0,1,213,25.16v-5a2,2,0,0,1,1.89-1.8h6.72V15.89C221.59.57,232.94,0,235,0a42.45,42.45,0,0,1,4.83.28,2,2,0,0,1,1.89,1.8V6.91a2,2,0,0,1-1.89,1.89H239c-8.13.09-8.13,7.09-8.13,7.09v2.46h9.08a1.94,1.94,0,0,1,1.89,1.8v5a2,2,0,0,1-1.89,1.89Z"
        />
        <path
          class="cls-1"
          d="M249.18,75a1.93,1.93,0,0,1-1.8-1.7V68.48a1.94,1.94,0,0,1,1.8-1.8h1c4.82,0,8.8-.75,10.4-6.24l-15.7-40a2.15,2.15,0,0,1,2-2.08h6a2.06,2.06,0,0,1,1.8,1.32l10.78,28.47,10.78-28.47a2,2,0,0,1,1.8-1.32h6a2.15,2.15,0,0,1,2,2.08L269.23,63.09C265.16,73.5,258.07,75,252.39,75Z"
        />
        <path
          class="cls-1"
          d="M68,48.12V34.43a34,34,0,0,0-34-34h0a34,34,0,1,0,0,68H47.69V58.27H34A23.85,23.85,0,1,1,57.84,34.43V48.12Z"
        />
        <rect class="cls-2" x="57.84" y="58.27" width="10.15" height="10.15" />
        <rect class="cls-3" x="47.69" y="58.27" width="10.15" height="10.15" />
        <rect class="cls-4" x="67.99" y="58.27" width="10.15" height="10.15" />
        <rect class="cls-5" x="57.84" y="48.12" width="10.15" height="10.15" />
        <rect class="cls-6" x="57.84" y="68.42" width="10.15" height="10.15" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {}
</script>

<style scoped>
.cls-1 {
  fill: #fff;
}
.cls-2 {
  fill: #383d73;
}
.cls-3 {
  fill: #e44e39;
}
.cls-4 {
  fill: #00a6e0;
}
.cls-5 {
  fill: #8c5ba4;
}
.cls-6 {
  fill: #f9a51a;
}
</style>
