<template>
  <div class="flex h-2 overflow-hidden rounded text-xs" :class="trackBgClass">
    <div
      :style="{ width: fillPercent }"
      class="flex flex-col justify-center whitespace-nowrap text-center shadow-none"
      :class="[textClass, fillBgClass]"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    trackBgClass: {
      type: String,
      default: 'bg-green-200',
    },
    fillBgClass: {
      type: String,
      default: 'bg-green-500',
    },
    textClass: {
      type: String,
      default: 'text-white',
    },
    modelValue: {
      type: Number,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    fillPercent() {
      return `${this.modelValue < 1 && this.modelValue > 0 ? this.modelValue * 100 : this.modelValue}%`
    },
  },
}
</script>
