<template>
  <h1 v-if="path && path.length > 0" class="mb-8 font-heading text-xl font-bold">
    <span v-for="(pathObject, index) in path" :key="pathObject.name">
      <span v-if="index" class="font-medium text-blue-600 mx-1">/</span>
      <inertia-link v-if="pathObject.url" class="text-blue-600" :href="pathObject.url">
        {{ pathObject.name }}
      </inertia-link>
      <span v-else>{{ pathObject.name }}</span>
    </span>
  </h1>
</template>

<script>
export default {
  props: {
    path: Array,
  },
}
</script>
