<template>
  <div @click.self="$emit('click', $event.target.value)">
    <label class="flex items-center p-3">
      <input
        :id="id"
        ref="input"
        v-bind="$attrs"
        class="form-checkbox mr-3 h-5 w-5 rounded-xl accent-ts-red-500 outline-none"
        type="checkbox"
        :checked="modelValue"
        @change.self="$emit('update:modelValue', $event.target.checked)"
      />
      {{ label }}
    </label>
    <div v-if="error" class="form-error">{{ error }}</div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    id: String,
    modelValue: Boolean,
    label: String,
    error: String,
    type: String,
  },
  methods: {
    focus() {
      this.$refs.input.focus()
    },
    select() {
      this.$refs.input.select()
    },
  },
}
</script>
