const AnswerableEnum = Object.freeze({
  'form-checkbox-answer': 'CheckboxAnswerField',
  'form-date-time-answer': 'DateTimeAnswerField',
  'form-document-number-answer': 'DocumentNumberAnswerField',
  'form-person-answer': 'PersonAnswerField',
  'form-location-answer': 'LocationAnswerField',
  'form-media-answer': 'MediaAnswerField',
  'form-number-answer': 'NumberAnswerField',
  'form-select-answer': 'SelectAnswerField',
  'form-slider-answer': 'SliderAnswerField',
  'form-text-answer': 'TextAnswerField',
})

const AnswerableViewEnum = Object.freeze({
  'form-checkbox-answer': 'CheckboxAnswerView',
  'form-date-time-answer': 'TextAnswerView',
  'form-document-number-answer': 'TextAnswerView',
  'form-person-answer': 'TextAnswerView',
  'form-location-answer': 'TextAnswerView',
  'form-media-answer': 'MediaAnswerView',
  'form-number-answer': 'TextAnswerView',
  'form-select-answer': 'SelectAnswerView',
  'form-slider-answer': 'TextAnswerView',
  'form-text-answer': 'TextAnswerView',
})

export { AnswerableEnum, AnswerableViewEnum }
