<template>
  <div>
    <div class="my-6 mb-12">
      <span v-if="item.options.is_required" class="text-red-700">*</span>
      <span>{{ item.title }}</span>
    </div>
    <Slider
      v-model="value"
      :min="item.options.min"
      :max="item.options.max"
      :step="item.options.increment"
      :format="tooltipFormat"
      class="slider-red"
    />
  </div>
</template>
<script>
import Slider from '@vueform/slider'

export default {
  components: {
    Slider,
  },
  props: {
    modelValue: String,
    item: Object,
    error: [Object, String],
  },
  emits: ['update:modelValue'],
  data() {
    return {
      value: this.modelValue ?? 5,
      options: {
        min: parseInt(this.item.options.min ?? 1),
        max: parseInt(this.item.options.max ?? 10),
        increment: parseInt(this.item.options.increment ?? 2),
      },
    }
  },
  computed: {
    tooltipFormat() {
      return Number.isInteger(this.options.increment) ? { decimals: 0 } : { decimals: 2 }
    },
  },
  watch: {
    value() {
      this.$emit('update:modelValue', this.value)
    },
  },
}
</script>

<style src="@vueform/slider/themes/default.css"></style>
<style scoped>
.slider-red {
  --slider-connect-bg: #dd163d;
  --slider-tooltip-bg: #dd163d;
  --slider-handle-ring-color: #ef444430;
}
</style>
