<template>
  <form @submit.prevent="(e) => $emit('submit', e)">
    <div class="flex items-center flex-1">
      <div class="flex w-full rounded form-input p-0">
        <div class="absolute inset-y-0 left-0 flex items-center justify-center z-20" @click="focusInput">
          <icon name="search" class="ml-3 block h-5 w-5 text-ts-gray-400" />
        </div>
        <input
          class="relative w-full px-6 py-3 leading-normal focus:z-10 focus:ring rounded-l pl-10 rounded-r m-px"
          autocomplete="off"
          type="text"
          name="search"
          :placeholder="placeholder"
          :value="searchValue"
          @input="onInput"
          @keydown="onKeyDown"
        />
        <div class="absolute inset-y-0 right-0 flex items-center justify-center z-20" @click="reset">
          <icon name="close" class="mr-3 block h-4 w-4 text-ts-gray-400" />
        </div>
        <slot name="searchByField" />
      </div>
    </div>
  </form>
</template>

<script>
export default {
  props: {
    modelValue: String,
    maxWidth: {
      type: Number,
      default: 300,
    },
    placeholder: {
      type: String,
      default: 'Search…',
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      searchValue: this.modelValue,
    }
  },
  watch: {
    searchValue(value) {
      this.$emit('update:modelValue', value)
    },
  },
  methods: {
    reset() {
      this.searchValue = null
    },
    onInput(event) {
      this.searchValue = event.target.value
    },
    onKeyDown(event) {
      if (event.key === 'Escape') {
        this.reset()
      }
    },
  },
}
</script>
