<template>
  <div>
    <div class="my-6">
      <span v-if="formAnswer.form_content.is_required" class="text-red-700">*</span>
      <span>{{ formAnswer.form_content.title }}</span>
    </div>
    <Slider
      v-model="value"
      :min="options.min"
      :max="options.max"
      :step="options.increment"
      :format="tooltipFormat"
      class="slider-red"
    />
  </div>
</template>
<script>
import Slider from '@vueform/slider'

export default {
  components: {
    Slider,
  },
  props: {
    modelValue: String,
    formAnswer: Object,
    error: [Object, String],
  },
  emits: ['update:modelValue'],
  data() {
    return {
      value:
        this.modelValue ??
        (this.formAnswer.form_content.form_questionable.max - this.formAnswer.form_content.form_questionable.min) / 2,
      options: {
        min: parseInt(this.formAnswer.form_content.form_questionable.min),
        max: parseInt(this.formAnswer.form_content.form_questionable.max),
        increment: parseInt(this.formAnswer.form_content.form_questionable.increment),
      },
    }
  },
  computed: {
    tooltipFormat() {
      return Number.isInteger(this.options.increment) ? { decimals: 0 } : { decimals: 2 }
    },
  },
  watch: {
    value() {
      this.$emit('update:modelValue', this.value)
    },
  },
}
</script>

<style src="@vueform/slider/themes/default.css"></style>
<style scoped>
.slider-red {
  --slider-connect-bg: #dd163d;
  --slider-tooltip-bg: #dd163d;
  --slider-handle-ring-color: #ef444430;
}
</style>
