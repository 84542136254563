<template>
  <icon
    name="arrow-long-up"
    stroke-width="2"
    class="h-4 w-4 stroke-current inline transform"
    :class="{
      'text-gray-600': isActive,
      'text-gray-400': !isActive,
      'rotate-180': isActive && isAscending,
    }"
  />
</template>
<script>
export default {
  props: {
    isActive: Boolean,
    isAscending: Boolean,
  },
}
</script>
