<template>
  <div class="rounded bg-white shadow">
    <div class="bg-gray-400 px-4 py-4 text-sm font-bold text-gray-700">{{ name }}</div>
    <div class="flex flex-wrap p-4" v-bind="$attrs">
      <slot disabled />
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    name: String,
  },
}
</script>
