<template>
  <div v-if="subscription.stripeStatus === 'active'" :class="`${cssClasses} bg-[#467082]/10 text-[#467082]`">
    {{ $t('Active') }}
  </div>
  <div
    v-else-if="subscription.stripeStatus === 'canceled'"
    :class="`${cssClasses} bg-qualify-red-600/10 text-qualify-red-600`"
  >
    {{ $t('Canceled') }}
  </div>
  <div
    v-else-if="subscription.stripeStatus === 'trialing'"
    :class="`${cssClasses} bg-sky-100 text-sky-700 border-sky-700`"
  >
    {{ $t('Free Trial') }}
  </div>
</template>
<script>
export default {
  props: {
    subscription: Object,
  },
  data() {
    return {
      cssClasses: 'inline-block px-2 py-1 rounded-md text-sm font-semibold text-center uppercase',
    }
  },
}
</script>
